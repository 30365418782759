/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { isBaseLevel as getIsBaseLevel } from '@atlassian/jira-software-roadmap-model/src/hierarchy/index.tsx';
import {
	BAR_HEIGHT,
	BASE_BAR_HEIGHT,
	START_DATE_PLACEHOLDER,
	DUE_DATE_PLACEHOLDER,
	START_AND_DUE_DATE_PLACEHOLDER,
} from '../../constants/chart-item.tsx';
import { CHART_BAR_Z_INDEX } from '../../constants/z-index.tsx';
import type { BarTheme, Placeholder } from '../../types/chart-item.tsx';
import { generateBarBorderRadius } from '../../utils/bar/styled.tsx';

export type BarStyledProps = {
	placeholder: Placeholder;
	left: number;
	right: number;
	theme: BarTheme;
	level: number;
};

const generateBarBackground = ({
	theme,
	placeholder,
}: {
	theme: BarTheme;
	placeholder: Placeholder;
}) => {
	if (placeholder === START_AND_DUE_DATE_PLACEHOLDER) {
		return `linear-gradient(90deg, ${theme.gradient} 0%, ${theme.background} 30%, ${theme.background} 70%, ${theme.gradient} 100%)`;
	}
	if (placeholder === START_DATE_PLACEHOLDER) {
		return `linear-gradient(90deg, ${theme.gradient} 0%, ${theme.background} 30%)`;
	}
	if (placeholder === DUE_DATE_PLACEHOLDER) {
		return `linear-gradient(90deg, ${theme.background} 70%, ${theme.gradient} 100%)`;
	}
	return theme.background;
};

export const getBaseBarStyles = ({
	level,
	placeholder,
	theme,
}: {
	placeholder: Placeholder;
	theme: BarTheme;
	level: number;
}): React.CSSProperties => ({
	position: 'absolute',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	minWidth: '3px',
	padding: 0,
	zIndex: `${CHART_BAR_Z_INDEX}`,
	height: `${getIsBaseLevel(level) ? `${BASE_BAR_HEIGHT}px` : `${BAR_HEIGHT}px`}`,
	borderRadius: `${generateBarBorderRadius(placeholder)}`,
	background: `${generateBarBackground({ placeholder, theme })}`,
});

export const BaseBar = ({
	placeholder,
	left,
	right,
	theme,
	level,
	children,
}: BarStyledProps & {
	children?: React.ReactNode;
}) => (
	<div
		css={baseBarStyles}
		style={{
			left: `${left}px`,
			right: `${right}px`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			height: `${getIsBaseLevel(level) ? `${BASE_BAR_HEIGHT}px` : `${BAR_HEIGHT}px`}`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			borderRadius: `${generateBarBorderRadius(placeholder)}`,
			background: `${generateBarBackground({ placeholder, theme })}`,
		}}
	>
		{children}
	</div>
);

const baseBarStyles = css({
	position: 'absolute',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	minWidth: token('space.050', '4px'),
	paddingTop: 0,
	paddingRight: token('space.050', '4px'),
	paddingBottom: 0,
	paddingLeft: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: `${CHART_BAR_Z_INDEX}`,
});
