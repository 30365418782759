/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { css, jsx } from '@compiled/react';
import { useIntl } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';
import { N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { inQuarterOrder, inQuarterOrderOld } from '../messages';

const headerStylesWithVisualRefresh = css({
	fontWeight: token('font.weight.semibold', '600'),
});

const headerStyles = css({
	font: token('font.body.UNSAFE_small'),
	color: token('color.text.subtlest', N300),
	marginTop: 0,
});

const headerTextTransformStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
});

const contrastStyles = css({
	color: token('color.text.accent.gray.bolder'),
});

type Props = {
	quarterNumber: number;
	isHighlighted: boolean;
	yearCaption?: string | undefined | null;
};

const QuarterCell = ({ quarterNumber, yearCaption, isHighlighted }: Props) => {
	const { formatMessage } = useIntl();

	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	const { startMonth, endMonth } = fg('platform-component-visual-refresh')
		? inQuarterOrder[quarterNumber]
		: inQuarterOrderOld[quarterNumber];

	return (
		<small
			css={[
				headerStyles,
				// eslint-disable-next-line @atlaskit/platform/no-preconditioning, @atlaskit/platform/ensure-feature-flag-prefix
				fg('platform-component-visual-refresh') &&
					// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
					fg('visual-refresh_drop_4') &&
					headerStylesWithVisualRefresh,
				// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
				!fg('platform-component-visual-refresh') && headerTextTransformStyles,
				isHighlighted && contrastStyles,
			]}
		>
			{`${formatMessage(startMonth)} – ${formatMessage(endMonth)}`} {yearCaption}
		</small>
	);
};

QuarterCell.defaultProps = {
	yearCaption: null,
};

export default QuarterCell;
export type { Props as APIProps };
