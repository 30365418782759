/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { Flex, xcss } from '@atlaskit/primitives';
import { N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages.tsx';

const containerStylesCompiledStylesOld = css({
	display: 'flex',
	flexDirection: 'row',
	overflow: 'hidden',
	alignItems: 'center',
	textOverflow: 'ellipsis',
	width: '100%',
	paddingTop: token('space.050', '4px'),
	paddingRight: '0px',
	paddingBottom: token('space.050', '4px'),
	paddingLeft: '0px',
});

const iconStylesCompiledStyles = css({
	width: token('space.200', '16px'),
	height: token('space.200', '16px'),
	borderRadius: '3px',
	flex: '0 0 auto',
});

const keyStylesCompiledStyles = css({
	font: token('font.body'),
	// I believe the lint rule is incorrectly flagging this, not recognising the ternery operator
	// eslint-disable-next-line @compiled/shorthand-property-sorting
	fontWeight: token('font.weight.medium'),
	outline: 'none',
	marginTop: 0,
	marginRight: 0,
	marginBottom: 0,
	marginLeft: token('space.050', '4px'),
	whiteSpace: 'nowrap',
	color: token('color.text.subtlest'),

	// I believe the lint rule is incorrectly flagging this, not recognising the ternery operator
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&, &:hover, &:active, &:focus': {
		textDecoration: 'none',
		color: token('color.text.subtlest'),
	},
});

const keyStylesCompiledStylesOld = css({
	// I believe the lint rule is incorrectly flagging this, not recognising the ternery operator
	// eslint-disable-next-line @compiled/shorthand-property-sorting
	font: token('font.body'),
	outline: 'none',
	marginTop: 0,
	marginRight: 0,
	marginBottom: 0,
	marginLeft: token('space.100', '8px'),
	whiteSpace: 'nowrap',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&, &:hover, &:active, &:focus': {
		color: token('color.text.subtlest', N300),
		textDecoration: 'none',
	},
});

const doneStylesCompiledStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&, &:hover, &:active, &:focus': {
		textDecoration: 'line-through',
	},
});

const titleStylesCompiledStyles = css({
	marginTop: 0,
	marginRight: 0,
	marginBottom: 0,
	marginLeft: token('space.050', '4px'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	flexGrow: 1,
});

const titleStylesCompiledStylesOld = css({
	marginTop: 0,
	marginRight: 0,
	marginBottom: 0,
	marginLeft: token('space.100', '8px'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	flexGrow: 1,
});

type SummaryType = 'div' | 'p';

type AnchorType = {
	isDone: boolean;
	testId?: string;
	children?: React.ReactNode;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

type SpanType = {
	isDone: boolean;
	testId?: string;
	children?: React.ReactNode;
} & React.HTMLAttributes<HTMLSpanElement>;

// these styles are reusable, so created in a shared component
export const SummaryContainer = ({ children }: { children?: React.ReactNode }) =>
	isVisualRefreshEnabled() ? (
		<Flex alignItems="center" xcss={containerStylesCompiledStyles}>
			{children}
		</Flex>
	) : (
		<div css={[containerStylesCompiledStylesOld]}>{children}</div>
	);
// these styles are reusable, so created in a shared component
export const SummaryIcon = ({ alt, src }: { alt: string; src: string }) => {
	const { formatMessage } = useIntl();
	const iconLabelMessage = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
		? messages.iconLabelIssueTermRefresh
		: messages.iconLabel;
	return (
		<Tooltip content={alt}>
			{(tooltipProps) => (
				<img
					src={src}
					alt={alt}
					css={iconStylesCompiledStyles}
					aria-label={alt ? formatMessage(iconLabelMessage, { issueType: alt }) : undefined}
					data-testid="roadmap.timeline-table-kit.common.ui.summary.icon"
					{...tooltipProps}
				/>
			)}
		</Tooltip>
	);
};

// https://github.com/facebook/react/issues/1881 (replaces previous implementation of !important not working in React)
export const SummaryKeyLink = ({ children, isDone, testId, ...rest }: AnchorType) => {
	const shouldApplyVisualRefreshChanges = isVisualRefreshEnabled();
	return (
		<a
			css={[
				shouldApplyVisualRefreshChanges ? keyStylesCompiledStyles : keyStylesCompiledStylesOld,
				isDone && doneStylesCompiledStyles,
			]}
			data-testid={testId}
			{...rest}
		>
			{children}
		</a>
	);
};

// https://github.com/facebook/react/issues/1881 (replaces previous implementation of !important not working in React)
export const SummaryKeyLabel = ({ children, isDone, testId, ...rest }: SpanType) => {
	const shouldApplyVisualRefreshChanges = isVisualRefreshEnabled();
	return (
		<span
			css={[
				shouldApplyVisualRefreshChanges ? keyStylesCompiledStyles : keyStylesCompiledStylesOld,
				isDone && doneStylesCompiledStyles,
			]}
			data-testid={testId}
			{...rest}
		>
			{children}
		</span>
	);
};

// these styles are reusable, so created in a shared component (currently used to render as `div` and `a` elements in the codebase)
export const SummaryTitle = ({
	type,
	testId,
	children,
	id,
}: {
	type: SummaryType;
	testId?: string;
	children?: React.ReactNode;
	id?: IssueId;
}) => {
	const shouldApplyVisualRefreshChanges = isVisualRefreshEnabled();
	switch (type) {
		case 'div':
			return (
				<div
					css={[
						shouldApplyVisualRefreshChanges
							? titleStylesCompiledStyles
							: titleStylesCompiledStylesOld,
					]}
					data-testid={testId}
					{...(id ? { id: `timeline-summary-title-${id}` } : {})}
				>
					{children}
				</div>
			);
		case 'p':
		default:
			return (
				<p
					css={[
						shouldApplyVisualRefreshChanges
							? titleStylesCompiledStyles
							: titleStylesCompiledStylesOld,
					]}
					data-testid={testId}
					{...(id ? { id: `timeline-summary-title-${id}` } : {})}
				>
					{children}
				</p>
			);
	}
};

const containerStylesCompiledStyles = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	width: '100%',
	paddingLeft: 'space.050',
	paddingTop: 'space.050',
	paddingBottom: 'space.050',
});
