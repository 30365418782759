/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { memo, type ReactNode, useEffect } from 'react';

import { css, jsx } from '@compiled/react';

import { BASE_LEVEL_ITEM_HEIGHT } from '../../../../common/constants';
import { useEnablements } from '../../../../common/context/enablements';
import { useIsItemSelected } from '../../../../common/context/items';
import type { ItemDropPayload, OnItemExpandChanged } from '../../../../common/types/callbacks';
import type { ItemId } from '../../../../common/types/item';

import { useListDropTarget } from './use-drop';

const containerStyles = css({
	position: 'absolute',
	display: 'flex',
	width: '100%',
});

type Props = {
	isParent: boolean;
	isExpanded: boolean;
	isInTransition: boolean;
	id: ItemId;
	parentId: ItemId | undefined;
	level: number;
	depth: number;
	ariaRowIndex: number | undefined;
	topOffset: number;
	children: ReactNode;
	itemHeight: number;
	childItemsHeight: number | undefined;
	onDrop: (id: ItemId, payload: ItemDropPayload) => void;
	onExpandChanged: OnItemExpandChanged;
};

const DropTarget = ({
	id,
	parentId,
	level,
	depth,
	ariaRowIndex,
	topOffset,
	children,
	itemHeight,
	childItemsHeight,
	isParent,
	isExpanded,
	isInTransition,
	onDrop,
	onExpandChanged,
}: Props) => {
	const [{ isTreeDrag }] = useEnablements();
	const [setDropRef, clearDropRef] = useListDropTarget({
		id,
		level,
		depth,
		parentId,
		isParent,
		isExpanded,
		isInTransition,
		isTreeDrag,
		childItemsHeight,
		onDrop,
		onExpandChanged,
	});
	const [isItemSelected] = useIsItemSelected(id);

	// Effects //
	useEffect(
		() => () => {
			// drop connections will be unsubscribed if NULL was passed to drop handler.
			clearDropRef();
		},
		[clearDropRef],
	);

	const height = depth > 0 ? BASE_LEVEL_ITEM_HEIGHT() : itemHeight;

	return (
		<div
			role="row"
			aria-rowindex={ariaRowIndex}
			aria-selected={isItemSelected}
			ref={setDropRef}
			css={containerStyles}
			style={{
				top: `${topOffset}px`,
				height: `${height}px`,
			}}
		>
			{children}
		</div>
	);
};

export type { Props as APIProps };
export default memo<Props>(DropTarget);
