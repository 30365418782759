import React, { type ReactNode, useCallback, useRef } from 'react';

import { useHeaderState } from '../../context/header';
import { useItemsContainerStyles } from '../../context/items';
import { ScrollMetaProviderSweetState, useScrollMetaActions } from '../../context/scroll-meta/main';
import { useSideEffectMarshal } from '../../context/side-effect-marshal';
import { useResizeObserver } from '../../utils/use-resize-observer';

import { type ScrollingState, useIsScrolling } from './use-is-scrolling';

type Props = {
	scope: string;
	scrollElement: HTMLElement | null;
	children: ReactNode;
};

/**
 * SCROLL LONG TASK SLO METRIC
 */
const SCROLL_COUNT_THRESHOLD = 5;

const Container = ({ scope, scrollElement }: { scope: string; scrollElement: HTMLElement }) => {
	const [{ headerHeight }] = useHeaderState();
	const { onScrollStateChanged } = useSideEffectMarshal();
	const [, { updateScrollMeta, updateScrollPosition }] = useScrollMetaActions();
	const [{ totalHeight }] = useItemsContainerStyles();

	const isScrolling = useRef(false);
	const scrollCount = useRef(0);

	const getAndUpdateScrollPosition = useCallback(() => {
		const { scrollTop, clientHeight } = scrollElement;
		const tableHeight = totalHeight + headerHeight;
		const scrollBottom = Math.max(0, tableHeight - (scrollTop + clientHeight));

		updateScrollPosition({
			scrollTop,
			scrollBottom,
		});
	}, [scrollElement, totalHeight, updateScrollPosition, headerHeight]);

	const onScrollChange = useCallback(
		(scrollingState: ScrollingState) => {
			if (isScrolling.current !== scrollingState.isScrollingY) {
				if (
					// Send metric on every Nth scroll interaction
					scrollCount.current === SCROLL_COUNT_THRESHOLD
				) {
					isScrolling.current = scrollingState.isScrollingY;
				} else {
					scrollCount.current += 1;
				}
			}

			updateScrollMeta(scrollingState);

			if (!scrollingState.isScrollingY) {
				getAndUpdateScrollPosition();
			}

			onScrollStateChanged(scrollingState.isScrollingY);
		},
		[updateScrollMeta, getAndUpdateScrollPosition, onScrollStateChanged],
	);

	useIsScrolling(scrollElement, onScrollChange);
	useResizeObserver(scrollElement, getAndUpdateScrollPosition);

	return null;
};

const ScrollMetaContainer = ({ scope, scrollElement, children }: Props) => (
	<ScrollMetaProviderSweetState>
		{scrollElement && <Container scope={scope} scrollElement={scrollElement} />}
		{children}
	</ScrollMetaProviderSweetState>
);

export default ScrollMetaContainer;
