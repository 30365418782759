import React, {
	useRef,
	useCallback,
	type ComponentType,
	type ReactElement,
	type MouseEvent as ReactMouseEvent,
} from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { B100 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { withDragObserver } from '@atlassian/jira-drag-observer/src/ui/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { getDateFromTimestamp } from '@atlassian/jira-software-roadmap-utils/src/utils/dates.tsx';

import { useEnablements } from '@atlassian/timeline-table/common/context/enablements';
import { useTimelineState } from '@atlassian/timeline-table/common/context/timeline';
import { isEnterOrSpaceKey } from '@atlassian/timeline-table/common/utils/events';

import {
	useItemSelect,
	type ItemClickHandler,
} from '@atlassian/timeline-table/common/utils/use-item-select';
import {
	DUE_DATE_TYPE,
	START_DATE_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	START_DATE_PLACEHOLDER,
	DUE_DATE_PLACEHOLDER,
	START_AND_DUE_DATE_PLACEHOLDER,
} from '../../../../common/constants/chart-item.tsx';
import { ROLLUP, INTERVAL } from '../../../../common/constants/date.tsx';
import { LEFT_AND_RIGHT, LEFT, RIGHT } from '../../../../common/constants/index.tsx';
import type {
	Placeholder,
	OnChartItemClick,
	OnChartItemUpdate,
	HorizontalDirection,
	BarTheme,
} from '../../../../common/types/chart-item.tsx';
import type { Position } from '../../../../common/types/common.tsx';
import type { DateType } from '../../../../common/types/date.tsx';
import { getBaseBarStyles } from '../../../../common/ui/bar/index.tsx';
import { getPlaceholderWhileDragging } from '../../../../common/utils/bar/placeholder.tsx';
import { getStartDate, getDueDate } from '../../../../common/utils/bar/positions.tsx';
import { getBarTheme } from '../../../../common/utils/bar/theme.tsx';
import { useChartItemInteractions } from '../../../../controllers/table-providers/chart-item-interaction/index.tsx';
import type {
	BarContentRenderers,
	BarContentProps,
	BarContentState,
	BarIconRendererProps,
	BarDependencyHandlerRendererProps,
	BarDependencyHandlerRendererState,
	BarDateLabelRendererProps,
} from './bar-content/types.tsx';
import { RoadmapDragHandle } from './drag-handle/index.tsx';
import messages from './messages.tsx';
import {
	getMinimumPositions,
	getUpdatedDates,
	getAnalyticsType,
	shouldInvertColor,
	fireAnalyticsStart,
	fireAnalyticsEnd,
} from './utils.tsx';
import { RoadmapBarWarnings } from './warning/index.tsx';

type Props = {
	isReadOnly: boolean;
	id: string;
	left: number;
	right: number;
	color: Color;
	level: number;
	startDateType: DateType;
	dueDateType: DateType;
	warnings: MessageDescriptor[];
	placeholder: Placeholder;
	DndLongTaskMetric: ComponentType<{
		isDragging: boolean;
		level: number;
	}>;
	renderBarContent: (props: BarContentProps, state: BarContentState) => ReactElement | null;
	onLeftClick?: OnChartItemClick;
	onRightClick?: (id: string, position: Position) => void;
	onUpdate: OnChartItemUpdate;
};

const StyledBar = ({
	left,
	right,
	placeholder,
	theme,
	level,
	shouldAnimate,
	isSelectEnabled,
	innerRef,
	containerRef,
	children,
	startDate,
	dueDate,
	color,
	'data-testid': testId,
	onMouseEnter,
	onMouseLeave,
	onContextMenu,
	...rest
}: {
	left: number;
	right: number;
	placeholder: Placeholder;
	theme: BarTheme;
	level: number;
	shouldAnimate: boolean;
	isSelectEnabled: boolean;
	innerRef: React.MutableRefObject<HTMLDivElement>;
	containerRef: React.MutableRefObject<HTMLDivElement>;
	onClick: (nativeEvent: React.SyntheticEvent) => void;
	onMouseDown: (nativeEvent: React.SyntheticEvent) => void;
	onDragStart: (nativeEvent: React.SyntheticEvent) => void;
	onDrag: (nativeEvent: React.SyntheticEvent) => void;
	onDragEnd: (nativeEvent: React.SyntheticEvent) => void;
	onMouseEnter: () => void;
	onMouseLeave: () => void;
	onContextMenu: ((event: ReactMouseEvent<HTMLElement>) => void) | undefined;
	startDate: number;
	dueDate: number;
	color: Color;
	'data-testid': string;
	children?: React.ReactNode;
}) => {
	const { formatMessage } = useIntl();

	return (
		<Box
			ref={containerRef}
			testId={`${testId}-container`}
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values
				...getBaseBarStyles({ level, theme, placeholder }),
				left: `${left}px`,
				right: `${right}px`,
				cursor: isSelectEnabled ? 'pointer' : 'default',
				transition: shouldAnimate ? 'left 0.1s ease, right 0.1s ease' : 'none',
			}}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<Box
				ref={innerRef}
				xcss={focusedBarStyles}
				aria-label={formatMessage(messages.gridCellAriaLabel, {
					startDate: getDateFromTimestamp(startDate),
					dueDate: getDateFromTimestamp(dueDate),
					color,
				})}
				onContextMenu={onContextMenu}
				testId={testId}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...rest}
			/>
			<Box xcss={barContentOverlayStyles}>
				<Box xcss={barContentWrapperStyles}>{children}</Box>
			</Box>
		</Box>
	);
};

const DraggableBar = withDragObserver(StyledBar);

const RoadmapBar = ({
	id,
	color,
	level,
	left: initialLeftPosition,
	right: initialRightPosition,
	startDateType,
	dueDateType,
	warnings,
	placeholder,
	isReadOnly,
	DndLongTaskMetric,
	onUpdate,
	onLeftClick,
	onRightClick,
	renderBarContent,
}: Props) => {
	const [{ isSelectEnabled }] = useEnablements();

	let handleClick: ItemClickHandler | undefined;

	if (fg('project_timeline_multi-select_and_checkboxes')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ handleClick } = useItemSelect(id));
	}

	const [{ timelineDuration, timelineWidth }] = useTimelineState();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const barContainerRef = useRef<HTMLElement | null>(null);
	const barButtonRef = useRef<HTMLElement | null>(null);

	const [
		{ isActive, dragType, ...interactionState },
		{ onDragStart, onDrag, onDragEnd, onLingerStart, onLingerEnd },
	] = useChartItemInteractions(id);
	const { formatMessage } = useIntl();
	const isHovered = isActive && !dragType;
	const isDragging = dragType !== undefined;
	const isDragExplicit = isDragging && isActive;

	const isResizeEnabled = startDateType !== INTERVAL && dueDateType !== INTERVAL;

	const leftPosition = interactionState.leftPosition ?? initialLeftPosition;
	const rightPosition = interactionState.rightPosition ?? initialRightPosition;
	const { minimumLeft, minimumRight } = getMinimumPositions(
		leftPosition,
		rightPosition,
		timelineWidth,
	);
	const isMinimumBar = minimumLeft !== undefined && minimumRight !== undefined;

	const newLeft =
		(isDragExplicit || isHovered) && minimumLeft !== undefined ? minimumLeft : leftPosition;
	const newRight =
		(isDragExplicit || isHovered) && minimumRight !== undefined ? minimumRight : rightPosition;
	const newPlaceholder = isDragExplicit
		? getPlaceholderWhileDragging(dragType, placeholder)
		: placeholder;

	const prevStartDate = getStartDate(initialLeftPosition, timelineDuration, timelineWidth);
	const prevDueDate = getDueDate(initialRightPosition, timelineDuration, timelineWidth);
	const startDate = getStartDate(leftPosition, timelineDuration, timelineWidth);
	const dueDate = getDueDate(rightPosition, timelineDuration, timelineWidth);
	const theme = getBarTheme(color);

	// === CALLBACKS === //
	const getBarBoundingClientRect = useCallback(
		() => barContainerRef.current?.getBoundingClientRect(),
		[],
	);

	const onResizeStartLeft = () => {
		onDragStart(LEFT);
		if (fg('one_event_rules_them_all_fg')) {
			fireAnalyticsStart(START_DATE_TYPE, createAnalyticsEvent({}));
		}
	};

	const onResizeStartRight = () => {
		onDragStart(RIGHT);
		if (fg('one_event_rules_them_all_fg')) {
			fireAnalyticsStart(DUE_DATE_TYPE, createAnalyticsEvent({}));
		}
	};

	const onBarDragStart = () => {
		onDragStart(LEFT_AND_RIGHT);

		if (fg('one_event_rules_them_all_fg')) {
			const analyticsEvent = createAnalyticsEvent({});
			fireAnalyticsStart(START_DATE_TYPE, analyticsEvent);
			fireAnalyticsStart(DUE_DATE_TYPE, analyticsEvent);
		}
	};

	const onBarDragEnd = () => {
		const updatedDates = getUpdatedDates(startDate, dueDate, dragType);

		const analyticsEvent = createAnalyticsEvent({
			action: 'dragEnded',
			actionSubject: 'bar',
		});
		const attributes = {
			type: getAnalyticsType(updatedDates),
			level,
			isStartDateInferred: startDateType === ROLLUP || startDateType === INTERVAL,
			isDueDateInferred: dueDateType === ROLLUP || dueDateType === INTERVAL,
		};
		fireUIAnalytics(analyticsEvent, 'issueBar', attributes);

		if (fg('one_event_rules_them_all_fg')) {
			const endAnalyticsEvent = createAnalyticsEvent({});
			if (updatedDates.dueDate) {
				fireAnalyticsEnd(DUE_DATE_TYPE, endAnalyticsEvent);
			}
			if (updatedDates.startDate) {
				fireAnalyticsEnd(START_DATE_TYPE, endAnalyticsEvent);
			}
		}

		onDragEnd();
		onUpdate(id, { dates: updatedDates, meta: { isDragged: true } }, analyticsEvent);
	};

	const onBarRightClick = (event: ReactMouseEvent<HTMLElement>) => {
		if (onRightClick !== undefined) {
			event.preventDefault();
			const { top, height } = event.currentTarget.getBoundingClientRect();

			const analyticsEvent = createAnalyticsEvent({
				action: 'rightClicked',
				actionSubject: 'bar',
			});
			fireUIAnalytics(analyticsEvent, 'issueBar');
			onRightClick(id, {
				x: event.clientX,
				y: top + height,
			});
		}
	};

	const onBarLeftClickOld = useCallback(
		({ target, ctrlKey, metaKey, shiftKey }: MouseEvent | React.KeyboardEvent<HTMLElement>) => {
			if (target === barButtonRef.current) {
				const withCmd = ctrlKey || metaKey;
				const withShift = shiftKey;
				const analyticsEvent = createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'bar',
				});
				const itemClickMeta = { level, withCmd, withShift };

				fireUIAnalytics(analyticsEvent, 'issueBar', itemClickMeta);
				onLeftClick?.(id, itemClickMeta, analyticsEvent);
			}
		},
		[createAnalyticsEvent, id, level, onLeftClick],
	);

	const onBarLeftClickNew = useCallback(
		(event: MouseEvent | React.KeyboardEvent<HTMLElement>) => {
			const { target, metaKey, ctrlKey, shiftKey } = event;

			if (target !== barButtonRef.current) {
				return;
			}

			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'bar',
				actionSubjectId: 'issueBar',
				attributes: {
					level,
					withCmd: ctrlKey || metaKey,
					withShift: shiftKey,
				},
			});

			fireUIAnalytics(analyticsEvent);
			// Remove the optional chaining with project_timeline_multi-select_and_checkboxes
			handleClick?.(event, analyticsEvent, barButtonRef);

			const itemClickMeta = { level, withCmd: ctrlKey || metaKey, withShift: shiftKey };
			onLeftClick?.(id, itemClickMeta, analyticsEvent);
		},
		[createAnalyticsEvent, level, handleClick, onLeftClick, id],
	);

	const onBarLeftClick = fg('project_timeline_multi-select_and_checkboxes')
		? onBarLeftClickNew
		: onBarLeftClickOld;

	// === RENDER === //

	const renderDragHandle = (type: HorizontalDirection, hasPlaceholder: boolean) => {
		const shouldRenderHandle =
			(isHovered && dragType === undefined) || (dragType === type && isDragExplicit);

		return shouldRenderHandle && isResizeEnabled && !isReadOnly ? (
			<RoadmapDragHandle
				id={id}
				type={type}
				theme={theme}
				hasPlaceholder={hasPlaceholder}
				onDragStart={type === LEFT ? onResizeStartLeft : onResizeStartRight}
				onDrag={onDrag}
				onDragEnd={onBarDragEnd}
			/>
		) : null;
	};

	const renderGhostBar = () => {
		if (!isDragging) {
			return null;
		}

		return (
			<div
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/enforce-style-prop
					...getBaseBarStyles({ level, theme, placeholder }),
					left: `${initialLeftPosition}px`,
					right: `${initialRightPosition}px`,
					backgroundColor: theme.background,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					opacity: '0.3',
				}}
			/>
		);
	};

	const barContentProps = {
		id,
		dragType,
		prevStartDate,
		prevDueDate,
		startDate,
		dueDate,
		startDateType,
		dueDateType,
		leftPosition: newLeft,
		rightPosition: newRight,
		placeholder: newPlaceholder,
		isReadOnly,
		isMinimumBar,
		isColorInverted: shouldInvertColor(color),
		getBarBoundingClientRect,
		onBarMouseLeave: onLingerEnd,
	};
	const barContentState = { isActive, isHovered };

	const warningsDescription = () => (
		<VisuallyHidden
			id={`roadmap.timeline-table-kit.ui.chart-item-content.date-content.bar.warning-desc-${id}`}
		>
			{warnings.length > 0 &&
				`${formatMessage(messages.warningsCount, { count: warnings.length })} `}
			{warnings.map((errorMessage) => `${formatMessage(errorMessage)} `)}
		</VisuallyHidden>
	);

	const onKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLElement>) => {
			if (isSelectEnabled) {
				if (isEnterOrSpaceKey(e)) {
					// Prevents timeline from scrolling on space
					e.preventDefault();
					onBarLeftClick(e);
				}
			}
		},
		[isSelectEnabled, onBarLeftClick],
	);

	const renderDraggableBar = () => (
		//  NOTE: ts error needs to be fixed in withDragObserver from @atlassian/jira-drag-observer
		// @ts-expect-error - TS2322 -   Property 'children' does not exist on type 'IntrinsicAttributes & IntrinsicClassAttributes<DragObserver> & Pick<Readonly<Props>, never> & InexactPartial<...> & InexactPartial<...>'. */}
		<DraggableBar
			data-testid={`roadmap.timeline-table-kit.ui.chart-item-content.date-content.bar.draggable-bar-${id}`}
			innerRef={barButtonRef}
			left={newLeft}
			right={newRight}
			level={level}
			theme={theme}
			placeholder={newPlaceholder}
			shouldAnimate={isHovered && isMinimumBar && !isDragging}
			isSelectEnabled={isSelectEnabled}
			isDisabled={isReadOnly}
			onMouseEnter={onLingerStart}
			onMouseLeave={onLingerEnd}
			onDrag={onDrag}
			onDragStart={onBarDragStart}
			onDragEnd={onBarDragEnd}
			onContextMenu={!isReadOnly ? onBarRightClick : undefined}
			onClick={isSelectEnabled ? onBarLeftClick : undefined}
			containerRef={barContainerRef}
			onKeyDown={onKeyDown}
			tabIndex={0}
			startDate={startDate}
			dueDate={dueDate}
			color={color}
			role="button"
			aria-describedby={`roadmap.timeline-table-kit.ui.chart-item-content.date-content.bar.warning-desc-${id}`}
		>
			{renderBarContent(barContentProps, barContentState)}
			{renderDragHandle(
				LEFT,
				placeholder === START_DATE_PLACEHOLDER || placeholder === START_AND_DUE_DATE_PLACEHOLDER,
			)}
			{renderDragHandle(
				RIGHT,
				placeholder === DUE_DATE_PLACEHOLDER || placeholder === START_AND_DUE_DATE_PLACEHOLDER,
			)}
		</DraggableBar>
	);

	return (
		<>
			{renderGhostBar()}
			{renderDraggableBar()}
			{warningsDescription()}
			{!isDragExplicit && !isHovered && warnings.length > 0 && (
				<RoadmapBarWarnings warnings={warnings} right={newRight} />
			)}
			<DndLongTaskMetric isDragging={isDragging} level={level} />
		</>
	);
};

export { RoadmapBar };
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	BarContentRenderers,
	BarContentProps,
	BarContentState,
	BarIconRendererProps,
	BarDependencyHandlerRendererProps,
	BarDependencyHandlerRendererState,
	BarDateLabelRendererProps,
};

const focusedBarStyles = xcss({
	borderRadius: 'border.radius',
	boxSizing: 'border-box',
	width: '100%',
	height: '100%',

	':focus-visible': {
		outline: `2px solid ${token('color.border.focused', B100)}`,
		outlineOffset: 'space.025',
	},
});

const barContentOverlayStyles = xcss({
	padding: '0',
	paddingLeft: 'space.075',
	paddingRight: 'space.075',
	position: 'absolute',
	top: '0',
	left: '0',
	width: '100%',
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	pointerEvents: 'none',
});

const barContentWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	pointerEvents: 'auto',
});
