import React, { Component, Fragment, type ComponentType } from 'react';
import noop from 'lodash/noop';
import Anchor from '@atlaskit/primitives/anchor';
import { expVal } from '@atlassian/jira-feature-experiments';
import SuccessFlag from '@atlassian/jira-flags/src/common/ui/components/success-auto-dismiss-flag'; // ignore-for-ENGHEALTH-17759
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	ContextualAnalyticsData,
	MODAL,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { DescriptionProps } from './common/types.tsx';
import messages from './messages.tsx';

type Props = {
	issueKeys: IssueKey[];
	id: string | number;
	Description: ComponentType<DescriptionProps>;
	onDismissed: (id: number | string) => void;
	intl: IntlShape;
};

// eslint-disable-next-line jira/react/no-class-components
class IrrelevantItemCreatedFlag extends Component<Props> {
	static defaultProps = {
		onDismissed: noop,
	};

	onDismissed = () => {
		const { id, onDismissed } = this.props;
		onDismissed(id);
	};

	renderIssueUrls() {
		const { issueKeys } = this.props;

		return (
			<>
				{issueKeys.map((issueKey, index) => (
					<Fragment key={issueKey}>
						<Anchor href={`/browse/${issueKey}`}>{issueKey}</Anchor>
						{index < issueKeys.length - 1 && ', '}
					</Fragment>
				))}
			</>
		);
	}

	render() {
		const {
			id,
			issueKeys,
			Description,
			intl: { formatMessage },
		} = this.props;

		const issueCount = issueKeys.length;
		const issueUrls = this.renderIssueUrls();
		const getTitleMessage = () => {
			if (issueCount === 1) {
				return formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.titleIssueTermRefresh
						: messages.title,
				);
			}

			return formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.titleMultipleIssuesIssueTermRefresh
					: messages.titleMultipleIssues,
				{ issueCount },
			);
		};

		const title = getTitleMessage();

		return (
			<ContextualAnalyticsData sourceName="irrelevantItemCreated" sourceType={MODAL}>
				<SuccessFlag
					id={id}
					onDismissed={this.onDismissed}
					title={title}
					description={<Description issueCount={issueCount} issueUrls={issueUrls} />}
				/>
				<FireScreenAnalytics />
			</ContextualAnalyticsData>
		);
	}
}

export default injectIntl(IrrelevantItemCreatedFlag);
