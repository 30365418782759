import type { BoundingOptions } from '../common/types';

import type { Offsets } from './types';

type GetBoundingParameters = {
	element: HTMLElement;
	offsets: Offsets;
} & BoundingOptions;

/* Transforms the viewport's raw bounding client rectangle.
 * At a minimum, we need to manually exclude the offsets used to hide the native scrollbars.
 * Without it, logic that relies on the viewport boundaries would be slightly off.
 */
const calculateClientBounding = ({
	element,
	offsets,
	withTimelineOffset,
}: GetBoundingParameters) => {
	const { width, height, right, bottom, left, top } = element.getBoundingClientRect();
	const { timelineTop, timelineLeft, scrollbar } = offsets;

	const effectiveTimelineTop = withTimelineOffset ? timelineTop : 0;
	const effectiveTimelineLeft = withTimelineOffset ? timelineLeft : 0;

	return {
		width: width - scrollbar - effectiveTimelineLeft,
		height: height - scrollbar - effectiveTimelineTop,
		right: right - scrollbar,
		bottom: bottom - scrollbar,
		left: left + effectiveTimelineLeft,
		top: top + effectiveTimelineTop,
	};
};

/* Transforms the viewport's raw scroll attributes.
 * Unlike the bounding client rectangle, these attributes are not affected by the scrollbar offsets.
 * This is because they effectively measure the viewport's content (e.g. scrollWidth, scrollHeight).
 */
const calculateScrollBounding = ({
	element,
	offsets,
	withTimelineOffset,
}: GetBoundingParameters) => {
	const { scrollTop, scrollLeft, scrollHeight, scrollWidth, clientWidth, clientHeight } = element;
	const { timelineTop, timelineLeft } = offsets;

	const effectiveTimelineTop = withTimelineOffset ? timelineTop : 0;
	const effectiveTimelineLeft = withTimelineOffset ? timelineLeft : 0;

	return {
		scrollTop,
		scrollLeft,
		scrollRight: Math.max(scrollWidth - clientWidth - scrollLeft, 0),
		scrollBottom: Math.max(scrollHeight - clientHeight - scrollTop, 0),
		scrollHeight: scrollHeight - effectiveTimelineTop,
		scrollWidth: scrollWidth - effectiveTimelineLeft,
		isOverflowY: scrollHeight > clientHeight,
	};
};

export { calculateClientBounding, calculateScrollBounding };
