/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { memo, useCallback } from 'react';

import { css, jsx } from '@compiled/react';

import { zIndex } from '../../../../common/constants';
import { useListWidth } from '../../../../common/context/list';
import type { OnSetListWidth } from '../../../../common/types/callbacks';
import type { DndLongTaskMetricComponent } from '../../../../common/types/drag-and-drop';

import Resizer from './resizer';

const containerStyles = css({
	position: 'absolute',
	display: 'flex',
	top: 0,
	bottom: 0,
	left: 0,
	width: '100%',
	pointerEvents: 'none',
});

const listResizerContainerStyles = css({
	position: 'sticky',
	left: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.LIST_RESIZER,
});

type Props = {
	DndLongTaskMetric: DndLongTaskMetricComponent | undefined;
	onSetListWidth: OnSetListWidth;
};

const ListResizer = ({ DndLongTaskMetric, onSetListWidth }: Props) => {
	const [listWidth, { setListWidth }] = useListWidth();

	const onResize = useCallback(
		(width: number) => {
			setListWidth(width);
		},
		[setListWidth],
	);

	const onResizeEnd = useCallback(() => {
		onSetListWidth(listWidth);
	}, [listWidth, onSetListWidth]);

	return (
		<div css={containerStyles}>
			<div css={listResizerContainerStyles} style={{ width: `${listWidth}px` }}>
				<Resizer
					width={listWidth}
					DndLongTaskMetric={DndLongTaskMetric}
					onResize={onResize}
					onResizeEnd={onResizeEnd}
				/>
			</div>
		</div>
	);
};

export default memo<Props>(ListResizer);
