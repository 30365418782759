import React, { Fragment, memo } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';

import { listItemMenu } from '../../../../../../../common/constants';
import { ListItemMenuOption } from '../../../list-item-menu-option';
import type { ItemSectionOptionsProps } from '../../types';

import CreateItemMenu from './create-item-menu';
import MoveItemMenu from './move-item-menu';

const ItemSectionOptions = ({
	id,
	parentId,
	level,
	depth,
	isCreateChildEnabled,
	isCreateSiblingEnabled,
	onInlineCreateClicked,
	issuesSectionOptions,
	setInitialFocusRef,
	renderListItemMenuOption,
	setCloseMenu,
	setOpenModal,
	onDrop,
	onCreateTriggerClick,
}: ItemSectionOptionsProps) => {
	const getMenuOption = (optionId: string, index: number) => {
		switch (optionId) {
			case listItemMenu.CREATE_ITEM:
				return (
					<CreateItemMenu
						key={fg('jsw_roadmaps_timeline-fix-a11y-rain') ? undefined : optionId}
						id={id}
						parentId={parentId}
						level={level}
						depth={depth}
						isCreateChildEnabled={isCreateChildEnabled}
						isCreateSiblingEnabled={isCreateSiblingEnabled}
						onInlineCreateClicked={onInlineCreateClicked}
						setInitialFocusRef={index === 0 ? setInitialFocusRef : undefined}
						onCreateTriggerClick={onCreateTriggerClick}
						setCloseMenu={setCloseMenu}
					/>
				);
			case listItemMenu.MOVE_ITEM:
				return (
					<MoveItemMenu
						id={id}
						key={fg('jsw_roadmaps_timeline-fix-a11y-rain') ? undefined : optionId}
						level={level}
						parentId={parentId}
						setInitialFocusRef={index === 0 ? setInitialFocusRef : undefined}
						onDrop={onDrop}
						setCloseMenu={setCloseMenu}
					/>
				);
			default:
				return fg('jsw_roadmaps_timeline-fix-a11y-rain') ? (
					renderListItemMenuOption({
						id,
						optionId,
						setOpenModal: (event) => setOpenModal(event, optionId),
						setInitialFocusRef: index === 0 ? setInitialFocusRef : undefined,
						Option: ListItemMenuOption,
						onCreateTriggerClick,
						setCloseMenu,
					})
				) : (
					<Fragment key={optionId}>
						{renderListItemMenuOption({
							id,
							optionId,
							setOpenModal: (event) => setOpenModal(event, optionId),
							setInitialFocusRef: index === 0 ? setInitialFocusRef : undefined,
							Option: ListItemMenuOption,
							onCreateTriggerClick,
							setCloseMenu,
						})}
					</Fragment>
				);
		}
	};

	return fg('jsw_roadmaps_timeline-fix-a11y-rain') ? (
		<Box as="ul" xcss={listStyles}>
			{issuesSectionOptions.map(({ id: optionId }, index) => (
				<Box as="li" key={optionId} xcss={listItemStyles}>
					{getMenuOption(optionId, index)}
				</Box>
			))}
		</Box>
	) : (
		<>{issuesSectionOptions.map(({ id: optionId }, index) => getMenuOption(optionId, index))}</>
	);
};

const listStyles = xcss({
	padding: '0',
	margin: '0',
	listStyleType: 'none',
});

const listItemStyles = xcss({
	margin: '0',
});

export default memo<ItemSectionOptionsProps>(ItemSectionOptions);
