/** @jsx jsx */
import React, { useEffect, memo, useCallback, type SetStateAction, type Dispatch } from 'react';
import { css, jsx } from '@compiled/react';
import CheckMarkIcon from '@atlaskit/icon/utility/check-mark';
import { Box, xcss } from '@atlaskit/primitives';
import { N0, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { DEFAULT_SUBHEADER_HEIGHT } from '@atlassian/timeline-table/common/constants';
import { useHeaderState } from '@atlassian/timeline-table/common/context/header';
import { useTimelineState } from '@atlassian/timeline-table/common/context/timeline';
import type { HeaderItem } from '@atlassian/timeline-table/common/types/header';
import { RELEASES_HEADER_ID } from '../../../common/constants/index.tsx';
import {
	COMPLETED,
	WARNING,
	MARKER_WIDTH,
	MARKER_WIDTH_WITH_SPACE,
	MARKER_COLORS,
	MARKER_BORDER_WIDTH,
} from '../../../common/constants/key-date.tsx';
import type { KeyDate, KeyDateStatus } from '../../../common/types/key-date.tsx';
import { getKeyDatesGroups } from '../../../common/utils/key-date/grouping.tsx';
import { getHighlightLines } from '../../../common/utils/key-date/highlight-lines.tsx';
import { CompletedIcon } from './completed-icon/index.tsx';
import { FailedIcon } from './failed-icon/index.tsx';
import { MarkerWithTooltip } from './tooltip/index.tsx';

// @Export for testing
export const getHighlightLineHeightInHeader = (
	headerItems: HeaderItem[],
	headerItemsOffsetTop: {
		[key: string]: number;
	},
	headerHeight: number,
): number => {
	const releasesRow = headerItems.find((headerItem) => headerItem.id === RELEASES_HEADER_ID);
	const releasesRowHeight =
		releasesRow && releasesRow.height !== undefined ? releasesRow.height : DEFAULT_SUBHEADER_HEIGHT;

	return (
		headerHeight -
		headerItemsOffsetTop[RELEASES_HEADER_ID] -
		releasesRowHeight / 2 -
		MARKER_WIDTH / 2 +
		MARKER_BORDER_WIDTH
	);
};

export type Props = {
	/**
	 * Key dates collection
	 */
	keyDates: ReadonlyArray<KeyDate>;
	highlightedKeyDates: string[];
	/**
	 * render props method to show the Popup content when any key date been pressed
	 * @param keyDate collection of key dates
	 * @returns
	 */
	renderPopupContent: (
		keyDate: ReadonlyArray<KeyDate>,
		setInitialFocusRef?: Dispatch<SetStateAction<HTMLElement | null>>,
	) => React.ReactNode;
};

const RoadmapKeyDates = ({
	keyDates: keyDatesProp,
	highlightedKeyDates,
	renderPopupContent,
}: Props) => {
	const [{ timelineDuration, timelineWidth, timelineMode }] = useTimelineState();

	const [{ headerItems, headerItemsOffsetTop, headerHeight }] = useHeaderState();

	const highlightLineHeight = getHighlightLineHeightInHeader(
		headerItems,
		headerItemsOffsetTop,
		headerHeight,
	);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const keyDateGroups = getKeyDatesGroups({
		keyDates: keyDatesProp,
		timelineMode,
		timelineDuration,
		timelineWidth,
	});

	let highlightLines;
	if (highlightedKeyDates.length > 0) {
		highlightLines = getHighlightLines({ keyDateGroups, highlightedKeyDates });
	}

	const getMarkerContent = (count: number, status: KeyDateStatus) => {
		if (count > 1) {
			return count > 9 ? '9+' : count;
		}
		if (status === WARNING) {
			return <FailedIcon />;
		}
		if (status === COMPLETED) {
			return isVisualRefreshEnabled() ? (
				<CheckMarkIcon label="" color="currentColor" />
			) : (
				<CompletedIcon />
			);
		}
		return null;
	};

	useEffect(() => {
		const numberOfKeyDatesPerGroup = keyDateGroups.map(({ keyDates }) => keyDates.length);
		const maxNumberOfKeyDatesInAGroup = Math.max(...numberOfKeyDatesPerGroup);
		const numberOfWarning = keyDateGroups.filter(({ status }) => status === WARNING).length;

		const analyticsEvent = createAnalyticsEvent({
			action: 'mounted',
			actionSubject: 'keyDatesHeader',
		});
		fireUIAnalytics(analyticsEvent, 'keyDates', {
			maxNumberOfKeyDatesInAGroup,
			numberOfWarning,
			numberOfMarker: keyDateGroups.length,
			timelineMode,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timelineMode]);

	/**
	 * Callback to render the user selected key dates flyout popup content
	 */
	const renderSelectedKeyDatePopupContent = useCallback(
		(selectedKeyDates: KeyDate[]) =>
			({
				setInitialFocusRef,
			}: {
				setInitialFocusRef?: Dispatch<SetStateAction<HTMLElement | null>>;
			}) =>
				renderPopupContent(selectedKeyDates, setInitialFocusRef),
		[renderPopupContent],
	);

	return (
		<>
			{keyDateGroups.map(({ keyDates, status, maxWidth, leftPosition, id }, index) => (
				<MarkerWithTooltip
					key={index}
					leftPosition={leftPosition}
					keyDates={keyDates}
					status={status}
					id={id}
					renderPopupContent={renderSelectedKeyDatePopupContent(keyDates)}
				>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */}
					<span css={markerStyles} style={{ backgroundColor: MARKER_COLORS[status] }}>
						{getMarkerContent(keyDates.length, status)}
					</span>
					{keyDates.length === 1 &&
						maxWidth > MARKER_WIDTH_WITH_SPACE &&
						(isVisualRefreshEnabled() ? (
							<Box
								as="span"
								xcss={labelStyle}
								style={{
									// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
									maxWidth: `${maxWidth - MARKER_WIDTH_WITH_SPACE}px`,
								}}
							>
								{keyDates[0].name}
							</Box>
						) : (
							<span
								css={labelStyles}
								style={{
									// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
									maxWidth: `${maxWidth - MARKER_WIDTH_WITH_SPACE}px`,
								}}
							>
								{keyDates[0].name}
							</span>
						))}
				</MarkerWithTooltip>
			))}
			{highlightLines &&
				highlightLines.map(({ left, id, status }) => (
					<span
						key={id}
						css={highlightLineStyles}
						style={{
							height: `${highlightLineHeight}px`,
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
							backgroundColor: MARKER_COLORS[status],
							left: `${left}px`,
						}}
					/>
				))}
		</>
	);
};

RoadmapKeyDates.whyDidYouRender = true;
RoadmapKeyDates.defaultProps = {
	highlightedKeyDates: [],
};
const RoadmapKeyDatesMemoed = memo<Props>(RoadmapKeyDates);
RoadmapKeyDatesMemoed.displayName = 'RoadmapKeyDates';

export { RoadmapKeyDatesMemoed as RoadmapKeyDates };

const markerStyles = css({
	'--icon-primary-color': token('color.icon.inverse', N0),
	font: token('font.body.small'),
	fontWeight: token('font.weight.bold'),
	color: token('color.text.inverse', N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${MARKER_WIDTH}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${MARKER_WIDTH}px`,
	padding: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '100%',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderWidth: `${MARKER_BORDER_WIDTH}px`,
	borderStyle: 'solid',
	borderColor: `${token('color.border.inverse', N0)}`,
	overflow: 'hidden',
});

const highlightLineStyles = css({
	position: 'absolute',
	display: 'block',
	width: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `calc(50% + ${MARKER_WIDTH / 2 - MARKER_BORDER_WIDTH}px)`,
});

const labelStyles = css({
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	// needed display setting as max-width doesn't work for inline styled element (in this case its span)
	display: 'inline-block',
	verticalAlign: 'middle',
	paddingTop: 0,
	paddingRight: token('space.050', '4px'),
	paddingBottom: 0,
	paddingLeft: token('space.050', '4px'),
	overflow: 'hidden',
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.medium'),
	marginTop: 0,
	color: token('color.text', N800),
});

const labelStyle = xcss({
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	// needed display setting as max-width doesn't work for inline styled element (in this case its span)
	display: 'inline-block',
	verticalAlign: 'middle',
	padding: 'space.0',
	paddingRight: 'space.050',
	paddingLeft: 'space.050',
	overflow: 'hidden',
	font: 'font.body.UNSAFE_small',
	fontWeight: 'font.weight.medium',
	marginTop: 'space.0',
	color: 'color.text.subtle',
});
