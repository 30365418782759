import React from 'react';

import { useIntl } from 'react-intl-next';

import Heading from '@atlaskit/heading';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';

import { ListItemMenuOption } from '../../../list-item-menu-option';
import type { CustomOptionsProps } from '../../types';
import { renderOverrideLabel } from '../../utils';

const CustomOptions = ({
	id,
	customOtherSections,
	onCreateTriggerClick,
	setCloseMenu,
	setInitialFocusRef,
	setOpenModal,
	renderListItemMenuOption,
}: CustomOptionsProps) => {
	const { formatMessage } = useIntl();

	return (
		<>
			{customOtherSections.map(({ id: sectionId, title, options }) => {
				if (!options || options.length === 0) {
					return null;
				}

				return (
					<Box
						xcss={sectionWrap}
						key={sectionId}
						testId={`roadmap.timeline-table.main.content.list.list-item.meatballs-button.meatball-popup.custom-options.${sectionId}`}
					>
						{title && (
							<Box xcss={headingWrap}>
								<Heading
									size="xxsmall"
									as={fg('jsw_roadmaps_timeline-fix-a11y-rain') ? 'h2' : 'div'}
								>
									{renderOverrideLabel(formatMessage, title)}
								</Heading>
							</Box>
						)}
						{fg('jsw_roadmaps_timeline-fix-a11y-rain') ? (
							<Box as="ul" xcss={listStyles}>
								{options.map(({ id: optionId }, index) => (
									<Box key={optionId} as="li" xcss={listItemStyles}>
										{renderListItemMenuOption({
											id,
											optionId,
											setInitialFocusRef: index === 0 ? setInitialFocusRef : undefined,
											setOpenModal: (event) => {
												setOpenModal(event, optionId);
											},
											Option: ListItemMenuOption,
											onCreateTriggerClick,
											setCloseMenu,
										})}
									</Box>
								))}
							</Box>
						) : (
							options.map(({ id: optionId }, index) => (
								<div key={optionId}>
									{renderListItemMenuOption({
										id,
										optionId,
										setInitialFocusRef: index === 0 ? setInitialFocusRef : undefined,
										setOpenModal: (event) => {
											setOpenModal(event, optionId);
										},
										Option: ListItemMenuOption,
										onCreateTriggerClick,
										setCloseMenu,
									})}
								</div>
							))
						)}
					</Box>
				);
			})}
		</>
	);
};

const listStyles = xcss({
	padding: '0',
	margin: '0',
	listStyleType: 'none',
});

const listItemStyles = xcss({
	margin: '0',
});

const headingWrap = xcss({
	marginTop: 'space.250',
	marginBottom: 'space.075',
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
});
const sectionWrap = xcss({
	borderTop: '1px solid',
	borderColor: 'color.border',
	marginTop: 'space.075',
});

export default CustomOptions;
