import { useViewport } from '@atlassian/timeline-table/common/context/viewport/context';
import { LEFT_AND_RIGHT } from '../../../constants/index.tsx';
import type { BarDragType, Placeholder } from '../../../types/chart-item.tsx';
import type { DateType } from '../../../types/date.tsx';
import type { Placement } from '../../formatted-date/types.tsx';
import { calculateDateVisibilities, getDatePlacement } from './utils.tsx';

type Props = {
	isDurationVisible: boolean;
	dragType: BarDragType | undefined;
	placeholder: Placeholder;
	startDateType: DateType;
	dueDateType: DateType;
	getBarBoundingClientRect: () => ClientRect | undefined;
};

export const useDatePlacement = ({
	dragType,
	placeholder,
	startDateType,
	dueDateType,
	isDurationVisible,
	getBarBoundingClientRect,
}: Props): Placement => {
	const { requestViewportBoundingClient } = useViewport();

	const rect = getBarBoundingClientRect();

	if (!rect) {
		return LEFT_AND_RIGHT;
	}

	const { isStartDateVisible, isDueDateVisible } = calculateDateVisibilities(
		rect,
		requestViewportBoundingClient(),
		placeholder,
		startDateType,
		dueDateType,
		isDurationVisible,
	);

	return getDatePlacement(dragType, isStartDateVisible, isDueDateVisible);
};
