import { defineMessages } from 'react-intl-next';

export default defineMessages({
	expand: {
		id: 'roadmap.timeline-table.main.content.list.list-item.expand',
		defaultMessage: 'Show child issues',
		description: 'Button label. Button expands and collapses a list item on Timelines issue list.',
	},
	createSibling: {
		id: 'roadmap.timeline-table.main.content.list.list-item.create-sibling',
		defaultMessage: 'Create issue',
		description:
			'Button label. Button begins issue creation process inline on Timelines issue list.',
	},
	createChild: {
		id: 'roadmap.timeline-table.main.content.list.list-item.create-child',
		defaultMessage: 'Create child issue',
		description:
			'Button label. Button begins issue creation process inline on Timelines issue list.',
	},
	done: {
		id: 'roadmap.timeline-table.main.content.list.list-item.done',
		defaultMessage: 'Done',
		description: 'Icon label. Icon appears when issue is in the done status category.',
	},
	expandIssueTermRefresh: {
		id: 'roadmap.timeline-table.main.content.list.list-item.expand-issue-term-refresh',
		defaultMessage: 'Show work items',
		description: 'Button label. Button expands and collapses a list item on Timelines issue list.',
	},
	createSiblingIssueTermRefresh: {
		id: 'roadmap.timeline-table.main.content.list.list-item.create-sibling-issue-term-refresh',
		defaultMessage: 'Create work item',
		description:
			'Button label. Button begins issue creation process inline on Timelines issue list.',
	},
	createChildIssueTermRefresh: {
		id: 'roadmap.timeline-table.main.content.list.list-item.create-child-issue-term-refresh',
		defaultMessage: 'Create child work item',
		description:
			'Button label. Button begins issue creation process inline on Timelines issue list.',
	},
});
