import React, { useCallback, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import TodayButton from '@atlassian/jira-aais-timeline-toolbar/src/ui/today-button/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { timelineScroller } from '@atlassian/jira-software-roadmap-utils/src/utils/timeline-scroller.tsx';
import { useTimelineState } from '@atlassian/timeline-table/common/context/timeline';
import { fg } from '@atlassian/jira-feature-gating';

const RoadmapTodayButton = ({ isHidden }: { isHidden?: boolean }) => {
	const [{ timelineOriginPosition }] = useTimelineState();

	const onClickCallback = useCallback(
		(_: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'todayButton');
			timelineScroller.scrollTo({ left: timelineOriginPosition });
		},
		[timelineOriginPosition],
	);

	return (
		<TodayButton
			onClick={onClickCallback}
			{...(fg('jsw_roadmaps_timeline-fix-a11y-rain') ? { isHidden } : {})}
		/>
	);
};

export { RoadmapTodayButton };
