import React, { memo, type ReactNode } from 'react';

import { FormattedMessage } from 'react-intl-next';

import Badge from '@atlaskit/badge';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { listItemMenu } from '../../../../../../../common/constants';
import { useItemsSelectionCount } from '../../../../../../../common/context/items';
import { useListItemMenuOptions } from '../../../../../../../common/context/list';
import type { BulkSelectedOptionsProps } from '../../types';
import CustomOptions from '../custom-options';

import messages from './messages';

const MultiSelectOptions = ({
	id,
	onCreateTriggerClick,
	setCloseMenu,
	setInitialFocusRef,
	setOpenModal,
	renderListItemMenuOption,
}: BulkSelectedOptionsProps) => {
	const [{ overrideLabels, customSectionsBulk }] = useListItemMenuOptions();
	const [itemsSelectionCount] = useItemsSelectionCount();

	const isLabelOverridden = Boolean(overrideLabels[listItemMenu.SELECTED_ITEM_COUNTER]);
	return (
		<>
			<Box testId="roadmap.timeline-table.main.content.list.list-item.meatballs-button.meatball-popup.multi-select-options.section">
				<Box xcss={bulkIndicatorContainer} aria-hidden>
					<FormattedMessage
						{...(isLabelOverridden
							? overrideLabels[listItemMenu.SELECTED_ITEM_COUNTER]
							: messages.selected)}
						values={{
							count: itemsSelectionCount,
							badge: (chunks: ReactNode[]) => <Badge appearance="primary">{chunks}</Badge>,
						}}
					/>
				</Box>
			</Box>

			{customSectionsBulk.length > 0 && (
				<CustomOptions
					id={id}
					customOtherSections={customSectionsBulk}
					onCreateTriggerClick={onCreateTriggerClick}
					setCloseMenu={setCloseMenu}
					setInitialFocusRef={setInitialFocusRef}
					setOpenModal={setOpenModal}
					renderListItemMenuOption={renderListItemMenuOption}
				/>
			)}
		</>
	);
};

const bulkIndicatorContainer = xcss({
	display: 'flex',
	paddingLeft: 'space.250',
	paddingRight: 'space.250',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
	alignItems: 'center',
	height: token('space.400', '32px'),
	color: 'color.text.subtle',
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.medium'),
	gap: 'space.100',
});

export default memo<BulkSelectedOptionsProps>(MultiSelectOptions);
