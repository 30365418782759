// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentType, type ComponentProps, type ReactElement } from 'react';
import type { Dispatch } from 'redux';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	isBaseLevel,
	BASE_LEVEL,
} from '@atlassian/jira-software-roadmap-model/src/hierarchy/index.tsx';
import { CONTEXT_MENU_TYPE } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/overlay.tsx';
import type {
	ItemClickMeta,
	OnChartItemClick,
	OnChartItemUpdate,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/chart-item.tsx';
import type { Position } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/common.tsx';
import type { Interval } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/interval.tsx';
import type {
	BarContentProps,
	BarContentState,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-item-content/date-content/bar/index.tsx';
import type { RoadmapChartItemContent } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-item-content/main.tsx';
import {
	CHART_BAR_RESIZE,
	type InteractionMetricType,
} from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';
import { EXPLICIT } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/date.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { PURPLE } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { DragAndDropLongTaskMetric } from '../../../../metrics/utils/long-task-drag-and-drop/index.tsx';
import {
	updateAndPersistIssue,
	type UpdateIssueAction,
} from '../../../../state/entities/issues/actions.tsx';
import { getCanUserEdit } from '../../../../state/selectors/permission.tsx';
import { getSprintIntervals } from '../../../../state/selectors/sprint/index.tsx';
import type { ChartData } from '../../../../state/selectors/table/chart-pure/common/types.tsx';
import { getChartDataHash } from '../../../../state/selectors/table/index.tsx';
import type { State } from '../../../../state/types.tsx';
import type { ShowIssueDetailsAction } from '../../../../state/ui/panel/actions.tsx';
import {
	showToolTip,
	selectItems,
	type ShowToolTipAction,
	type SelectItemsAction,
} from '../../../../state/ui/table/actions.tsx';
import { renderBarContent } from './bar-content/index.tsx';
import ChartItemContent from './view.tsx';

type Action = ShowToolTipAction | UpdateIssueAction | SelectItemsAction | ShowIssueDetailsAction;
type OwnProps = {
	isHovered: boolean;
	id: IssueId;
};
type DndLongTaskMetricProps = {
	isDragging: boolean;
	level: number;
};
type StateProps = {
	isReadOnly: boolean;
} & ChartData & {
		intervals: ReadonlyArray<Interval>;
		renderBarContent: (props: BarContentProps, state: BarContentState) => ReactElement;
		DndLongTaskMetric: ComponentType<DndLongTaskMetricProps>;
	};
type DispatchProps = {
	onUpdate: OnChartItemUpdate;
	// TODO: remove this with project_timeline_multi-select_and_checkboxes
	onLeftClick: OnChartItemClick;
	onRightClick: (id: string, position: Position) => void;
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
	onUpdate: (id, { dates, meta, intervalId }, event, hasOverdueReleaseDate) => {
		dispatch(
			updateAndPersistIssue(
				id,
				intervalId !== undefined ? { sprintId: intervalId } : { ...dates },
				event,
				{
					isDragged: !!meta?.isDragged,
					hasOverdueReleaseDate,
				},
			),
		);
	},
	onLeftClick: (
		id: string,
		{ level, withCmd, withShift }: ItemClickMeta,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		if (fg('project_timeline_multi-select_and_checkboxes')) {
			return;
		}

		dispatch(selectItems({ id, level, withCmd, withShift }, analyticsEvent));
	},
	onRightClick: (id: string, position: Position) => {
		dispatch(showToolTip({ id, type: CONTEXT_MENU_TYPE, position }));
	},
});

const mergeProps = (
	stateProps: StateProps,
	dispatchProps: DispatchProps,
	{ id, isHovered }: OwnProps,
): JSX.LibraryManagedAttributes<
	typeof RoadmapChartItemContent,
	ComponentProps<typeof RoadmapChartItemContent>
> => ({
	...stateProps,
	...dispatchProps,
	isHovered,
	id: id.toString(),
});

const DndLongTaskMetric = ({ level, isDragging }: DndLongTaskMetricProps) => (
	<DragAndDropLongTaskMetric<InteractionMetricType>
		isDragging={isDragging}
		metricType={CHART_BAR_RESIZE}
		extraProps={{ isBaseLevel: isBaseLevel(level) }}
	/>
);

const defaultChartData: ChartData = {
	startDate: undefined,
	dueDate: undefined,
	color: PURPLE,
	placeholder: undefined,
	startDateType: EXPLICIT,
	dueDateType: EXPLICIT,
	warnings: [],
	level: BASE_LEVEL,
};

export default connect(
	(state: State, { id }: OwnProps): StateProps => {
		const chartData = getChartDataHash(state)[id];
		if (chartData === undefined) {
			log.safeErrorWithoutCustomerData(
				'roadmap.standard.view.chart-item-content',
				`ChartData is undefined: issueId ${id}`,
			);
		}
		return {
			...(chartData ?? defaultChartData),
			intervals: getSprintIntervals(state),
			isReadOnly: !getCanUserEdit(state),
			renderBarContent,
			DndLongTaskMetric,
		};
	},
	mapDispatchToProps,
	mergeProps,
)(ChartItemContent);
