import { useCallback, useEffect, useRef } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import type { ItemId } from '../../../../types/item';
import { useFocusMarshal } from '../index';

/* This hook is integral in our grid navigation functionality.
 * It registers grid cells to build up an understanding of the grids coordinate system.
 *
 * The "origin cell" is the entry point for the grid. If more than one origin cell is
 * provided, only the first will be considered.
 */
export const useGridCell = (rowId: ItemId, column: number, isOriginCell = false) => {
	const element = useRef<HTMLElement | null>(null);

	const { trackCell, untrackCell, focusCell, blurCell, initialiseRovingCell } = useFocusMarshal();

	// Ref callback is more robust for consumers than ref object
	const setCellRef = useCallback((ref: HTMLElement | null) => {
		element.current = ref;
	}, []);

	/* Lazily evaluating the cell co-ordinates helps prevent a series of edge-cases.
	 * Even for the same cell, their coordinates are not static, and depend on user interactions.
	 */
	const getData = useCallback(() => ({ rowId, column }), [rowId, column]);

	/* Lazily evaluating the interactive cell widgets helps prevent a series of edge-cases.
	 * For example, content being added or removed due to user interactions.
	 */
	const getContent = useCallback(() => {
		if (!element.current) {
			return [];
		}

		const keyboardFocusable = element.current.querySelectorAll(
			'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])',
		);

		return Array.from(keyboardFocusable).filter(
			(e): e is HTMLElement => e instanceof HTMLElement && !e.hasAttribute('disabled'),
		);
	}, []);

	const onFocus = useCallback(
		(event: FocusEvent) => {
			if (element.current && event.target instanceof HTMLElement) {
				focusCell(element.current, event.target);
			}
		},
		[focusCell],
	);

	const onBlur = useCallback(
		(event: FocusEvent) => {
			if (event.target instanceof HTMLElement) {
				blurCell(event.target);
			}
		},
		[blurCell],
	);

	useEffect(() => {
		if (!element.current || !fg('timeline_grid_navigation_m2')) {
			return;
		}

		/* useEffect's own cleanup will be called after element.current is already null
		 * This closure provides us access to a valid reference to update the Map
		 */
		const setup = (safeElement: HTMLElement) => {
			trackCell(safeElement, getData, getContent);
			isOriginCell && initialiseRovingCell(safeElement);

			// eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
			safeElement.addEventListener('focusin', onFocus);
			// eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
			safeElement.addEventListener('focusout', onBlur);

			return () => {
				untrackCell(safeElement);
				// eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
				safeElement.removeEventListener('focusin', onFocus);
				// eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
				safeElement.removeEventListener('focusout', onBlur);
			};
		};

		const cleanup = setup(element.current);

		return () => {
			cleanup();
		};
	}, [
		rowId,
		column,
		isOriginCell,
		getData,
		getContent,
		trackCell,
		untrackCell,
		initialiseRovingCell,
		onFocus,
		onBlur,
	]);

	useEffect(() => {
		if (!element.current || fg('timeline_grid_navigation_m2')) {
			return;
		}

		/* useEffect's own cleanup will be called after element.current is already null
		 * This closure provides us access to a valid reference to update the Map
		 */
		const setup = (safeElement: HTMLElement) => {
			trackCell(safeElement, getData, () => []);
			isOriginCell && initialiseRovingCell(safeElement);
			return () => untrackCell(safeElement);
		};

		const cleanup = setup(element.current);

		return () => {
			cleanup();
		};
	}, [rowId, column, isOriginCell, getData, trackCell, untrackCell, initialiseRovingCell]);

	return setCellRef;
};
