/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { memo } from 'react';

import { css, jsx } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { BASE_LEVEL_ITEM_HEIGHT, zIndex } from '../../../../common/constants';
import { useListWidth } from '../../../../common/context/list';
import { getListContentPaddingLeft } from '../../../../common/styled/list';
import type { ItemId } from '../../../../common/types/item';
import type { RenderListItemContent, RenderListItemSecondaryContent } from '../../../../renderers';

const listItemLiteCellStyles = css({
	position: 'sticky',
	display: 'flex',
	boxSizing: 'border-box',
	alignItems: 'center',
	flex: '0 0 auto',
	left: 0,
	paddingRight: token('space.100', '8px'),
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.LIST,
});

type Props = {
	id: ItemId;
	depth: number;
	itemHeight: number;
	backgroundColor: string;
	renderListItemContent: RenderListItemContent;
	renderListItemSecondaryContent: RenderListItemSecondaryContent;
};

/* A non interactive version of the list-item with lightweight styles.
 * Leveraged during virtualisation to improve scroll performance.
 */
const ListItemLite = ({
	id,
	depth,
	itemHeight,
	backgroundColor,
	renderListItemContent,
	renderListItemSecondaryContent,
}: Props) => {
	const [listWidth] = useListWidth();
	const rendererProps = { id };
	const rendererState = { isVirtual: true, isHoveredOrFocused: false };

	const height = depth > 0 ? BASE_LEVEL_ITEM_HEIGHT() : itemHeight;

	return (
		<div
			role="gridcell"
			tabIndex={-1}
			css={listItemLiteCellStyles}
			style={{
				width: `${listWidth}px`,
				height: `${height}px`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				paddingLeft: `${getListContentPaddingLeft({ isParent: false, depth })}px`,
				backgroundColor,
			}}
		>
			{renderListItemContent(rendererProps, rendererState)}
			{renderListItemSecondaryContent(rendererProps, rendererState)}
		</div>
	);
};

export default memo<Props>(ListItemLite);
