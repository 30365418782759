/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { css, jsx } from '@compiled/react';

import { zIndex } from '../../../common/constants';
import PortalContainer from '../../../common/context/portal/portal-container/view';
import type { RenderChartOverlay } from '../../../renderers';

const containerStyles = css({
	position: 'absolute',
	top: 0,
	right: 0,
	bottom: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.HIGHEST,
	pointerEvents: 'none',
});

type Props = {
	listWidth: number;
	renderChartOverlay: RenderChartOverlay;
};

const ChartOverlay = ({ listWidth, renderChartOverlay }: Props) => (
	<div css={containerStyles} style={{ left: `${listWidth}px` }}>
		{renderChartOverlay()}
		<PortalContainer />
	</div>
);

export default ChartOverlay;
