/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { css, jsx } from '@compiled/react';
import { useIntl } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';
import { N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { inMonthOrder, inMonthOrderOld } from '../messages';

// merge headerStylesVisualRefresh and headerStyles when cleaning up visual refresh flags
const headerStylesVisualRefresh = css({
	fontWeight: token('font.weight.semibold', '600'),
});

const headerStyles = css({
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.medium'),
	color: token('color.text.subtlest', N300),
	marginTop: 0,
});

const headerTextTransformStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
});

const contrastStyles = css({
	color: token('color.text.accent.gray.bolder'),
});

type Props = {
	monthNumber: number;
	isHighlighted: boolean;
	yearCaption?: string | undefined | null;
};

const MonthCell = ({ monthNumber, yearCaption, isHighlighted }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<small
			css={[
				headerStyles,
				// eslint-disable-next-line @atlaskit/platform/no-preconditioning, @atlaskit/platform/ensure-feature-flag-prefix
				fg('platform-component-visual-refresh') &&
					// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
					fg('visual-refresh_drop_4') &&
					headerStylesVisualRefresh,
				// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
				!fg('platform-component-visual-refresh') && headerTextTransformStyles,
				isHighlighted && contrastStyles,
			]}
		>
			{formatMessage(
				// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
				fg('platform-component-visual-refresh')
					? inMonthOrder[monthNumber]
					: inMonthOrderOld[monthNumber],
			)}{' '}
			{yearCaption}
		</small>
	);
};

MonthCell.defaultProps = {
	yearCaption: null,
};

export default MonthCell;
export type { Props as APIProps };
