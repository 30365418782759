/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { memo, useCallback } from 'react';

import { css, jsx } from '@compiled/react';
import { useIntl } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';

import { MONTHS, WEEKS } from '../../../../common/constants';
import type {
	ColumnDuration,
	TimelineDuration,
	TimelineMode,
} from '../../../../common/types/timeline';
import { getColumnPositions, getTodayColumnIndex } from '../../../../common/utils/columns';

import {
	getColumnMonthNumbers,
	getColumnQuarterNumbers,
	getColumnWeekNumbers,
	getColumnYearCaptions,
	getTodayCoordinates,
} from './common/utils';
import { CalendarCellWrapper } from './common/wrapper';
import messages from './messages';
import MonthCell from './month';
import QuarterCell from './quarter';
import WeekCell from './week';

const containerStyles = css({
	position: 'relative',
	width: '100%',
	height: '100%',
});

type Props = {
	isHighlightToday: boolean;
	today: number;
	timelineMode: TimelineMode;
	timelineDuration: TimelineDuration;
	columnDurations: ColumnDuration[];
};

const ChartCalendarCells = ({
	isHighlightToday,
	today,
	timelineMode,
	timelineDuration,
	columnDurations,
}: Props) => {
	const { formatMessage, formatDate } = useIntl();

	const todayMessage = fg('jsw_roadmaps_timeline-fix-a11y-rain')
		? formatMessage(messages.today, { date: formatDate(today) })
		: undefined;

	const todayColumnIndex = getTodayColumnIndex(today, columnDurations);

	const getIsHighlighted = useCallback(
		(index: number) => isHighlightToday && todayColumnIndex === index,
		[isHighlightToday, todayColumnIndex],
	);

	const renderWeekCells = useCallback(
		(columnPositions: [number, number][]) => {
			const todayCoordinates = isHighlightToday
				? getTodayCoordinates(today, columnDurations)
				: undefined;
			const columnWeekNumbers = getColumnWeekNumbers(columnDurations);
			const yearCaptions = getColumnYearCaptions(
				today,
				columnDurations,
				fg('jsw_roadmaps_timeline-fix-year-caption-weeks-mode') ? true : undefined,
			);

			return columnPositions.map(([left, right], index) => {
				const isHighlighted = getIsHighlighted(index);

				return (
					<CalendarCellWrapper
						key={index}
						left={left}
						right={right}
						column={index}
						label={
							isHighlighted && fg('jsw_roadmaps_timeline-fix-a11y-rain') ? todayMessage : undefined
						}
					>
						<WeekCell
							columnIndex={index}
							isHighlightToday={isHighlightToday}
							todayCoordinates={todayCoordinates}
							weekNumbers={columnWeekNumbers[index]}
							yearCaption={yearCaptions[index]}
							isHighlighted={isHighlighted}
						/>
					</CalendarCellWrapper>
				);
			});
		},
		[isHighlightToday, today, todayMessage, columnDurations, getIsHighlighted],
	);

	const renderMonthCells = useCallback(
		(columnPositions: [number, number][]) => {
			const columnMonthNumbers = getColumnMonthNumbers(columnDurations);
			const yearCaptions = getColumnYearCaptions(today, columnDurations);

			return columnPositions.map(([left, right], index) => {
				const isHighlighted = getIsHighlighted(index);

				return (
					<CalendarCellWrapper
						key={index}
						left={left}
						right={right}
						column={index}
						label={
							isHighlighted && fg('jsw_roadmaps_timeline-fix-a11y-rain') ? todayMessage : undefined
						}
					>
						<MonthCell
							monthNumber={columnMonthNumbers[index]}
							yearCaption={yearCaptions[index]}
							isHighlighted={isHighlighted}
						/>
					</CalendarCellWrapper>
				);
			});
		},
		[columnDurations, today, todayMessage, getIsHighlighted],
	);

	const renderQuarterCells = useCallback(
		(columnPositions: [number, number][]) => {
			const columnQuarterNumbers = getColumnQuarterNumbers(columnDurations);
			const yearCaptions = getColumnYearCaptions(today, columnDurations);

			return columnPositions.map(([left, right], index) => {
				const isHighlighted = getIsHighlighted(index);

				return (
					<CalendarCellWrapper
						key={index}
						left={left}
						right={right}
						column={index}
						label={
							isHighlighted && fg('jsw_roadmaps_timeline-fix-a11y-rain') ? todayMessage : undefined
						}
					>
						<QuarterCell
							quarterNumber={columnQuarterNumbers[index]}
							yearCaption={yearCaptions[index]}
							isHighlighted={isHighlighted}
						/>
					</CalendarCellWrapper>
				);
			});
		},
		[columnDurations, today, todayMessage, getIsHighlighted],
	);

	const renderColumnsForMode = useCallback(() => {
		const columnPositions = getColumnPositions(timelineDuration, columnDurations);

		if (timelineMode === WEEKS) {
			return renderWeekCells(columnPositions);
		}
		if (timelineMode === MONTHS) {
			return renderMonthCells(columnPositions);
		}

		return renderQuarterCells(columnPositions);
	}, [
		timelineMode,
		columnDurations,
		timelineDuration,
		renderWeekCells,
		renderMonthCells,
		renderQuarterCells,
	]);

	return <div css={containerStyles}>{renderColumnsForMode()}</div>;
};

ChartCalendarCells.whyDidYouRender = true;
ChartCalendarCells.displayName = 'ChartCalendarCells';

export default memo<Props>(ChartCalendarCells);
export type { Props as APIProps };
