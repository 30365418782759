/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

import { useEnablements } from '@atlassian/timeline-table/common/context/enablements';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Progress } from '../../common/types/progress.tsx';
import { RoadmapProgressBar as ProgressBar } from '../../common/ui/progress-bar/main.tsx';
import Summary, { type ProgressMetadata } from './summary/index.tsx';

type Props = {
	isDone: boolean;
	isRichContentEnabled: boolean;
	title: string;
	iconUrl: string;
	iconAltText: string;
	itemKey?: string;
	progress?: Progress;
	highlightText: string;
	progressMetadata?: ProgressMetadata;
	// clean up chronos_a11y_fixes_jtran2 remove optional type for id
	id?: IssueId;
};

const RoadmapListItemContent = ({
	title,
	iconUrl,
	iconAltText,
	itemKey,
	progress,
	highlightText,
	isDone,
	isRichContentEnabled,
	progressMetadata,
	id,
}: Props) => {
	let isMultiSelectEnabled;

	if (fg('project_timeline_multi-select_and_checkboxes')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[{ isMultiSelectEnabled }] = useEnablements();
	}

	return (
		<>
			<Summary
				title={title}
				itemKey={itemKey}
				iconUrl={iconUrl}
				iconAltText={iconAltText}
				highlightText={highlightText}
				isDone={isDone}
				isActive={isRichContentEnabled}
				progressMetadata={progressMetadata}
				{...(id ? { id } : {})}
			/>
			{progress &&
				(fg('project_timeline_multi-select_and_checkboxes') ? (
					<div
						css={containerStyles}
						style={{
							left: isMultiSelectEnabled ? token('space.1000') : token('space.600'),
							marginLeft:
								!isMultiSelectEnabled && isVisualRefreshEnabled()
									? token('space.150')
									: token('space.0'),
						}}
					>
						<ProgressBar isRichContentEnabled={isRichContentEnabled} progress={progress} />
					</div>
				) : (
					<div css={legacyContainerStyles}>
						<ProgressBar isRichContentEnabled={isRichContentEnabled} progress={progress} />
					</div>
				))}
		</>
	);
};

RoadmapListItemContent.defaultProps = {
	title: '',
	iconUrl: '',
	iconAltText: '',
	highlightText: '',
	isDone: false,
};

export { RoadmapListItemContent };

const legacyContainerStyles = css({
	position: 'absolute',
	left: token('space.600', '48px'),
	bottom: token('space.050', '4px'),
	width: '172px',
	height: token('space.050', '4px'),
});

const containerStyles = css({
	position: 'absolute',
	bottom: token('space.050'),
	width: '172px',
	height: token('space.050'),
});
