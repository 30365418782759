import React from 'react';
import type { KeyDate } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/key-date.tsx';
import { RoadmapHighlightLines } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-timeline-overlay/highlight-lines/index.tsx';

import { useTimelineState } from '@atlassian/timeline-table/common/context/timeline';

export type Props = {
	highlightedKeyDates: string[];
	getVersionsForKeyDate: (today: number) => ReadonlyArray<KeyDate>;
};

const HighlightLines = ({ highlightedKeyDates, getVersionsForKeyDate }: Props) => {
	const [{ today }] = useTimelineState();
	const keyDates = getVersionsForKeyDate(today);

	return highlightedKeyDates.length ? (
		<RoadmapHighlightLines highlightedKeyDates={highlightedKeyDates} keyDates={keyDates} />
	) : null;
};

export { HighlightLines };
