import memoizeOne from 'memoize-one';

import { CREATE_ITEM_TYPE, ITEM_TYPE } from '../../../constants';
import type {
	EnrichedCreateItem,
	EnrichedItem,
	FlattenedEnrichedItem,
} from '../../../types/hierarchy-enriched-item';
import type { FlatItem, ItemId } from '../../../types/item';

import { BOTTOM, OVERSCAN_COUNT, TOP } from './constants';
import type { State } from './types';

const getAccumulateHeight = memoizeOne((itemHeights: number[]): number[] =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	itemHeights.reduce<Array<any>>((acc, current, index) => {
		acc.push(current + (index > 0 ? acc[index - 1] : 0));
		return acc;
	}, []),
);

/**
 * Get sum height for ranges of rows
 * (end not included)
 * @param {*} startIndex
 * @param {*} endIndex
 * @param {*} itemHeights
 * @returns total height of rows
 */
export const getItemHeightForRows = (
	startIndex: number,
	endIndex: number,
	itemHeights: number[],
): number => {
	if (endIndex <= startIndex) {
		return 0;
	}

	const accumHeight = getAccumulateHeight(itemHeights);
	return accumHeight[endIndex - 1] - (startIndex === 0 ? 0 : accumHeight[startIndex - 1]);
};

/**
 * Return the placeholder heights for the items appear before and after given index
 */
export const getPlaceholderHeights = (
	index: number,
	itemHeights: number[],
	start: number,
	end: number,
) => {
	// Get row height for the items before index
	const before = getItemHeightForRows(start, index, itemHeights);
	const after = getItemHeightForRows(
		index + 1, // Get row height for the items after index
		end,
		itemHeights,
	);
	return {
		before,
		after,
	};
};

/**
 * Get all the higher level items of a given item.
 * @param {*} items
 * @param {*} item
 */
export const getAncestors = (
	items: FlattenedEnrichedItem[],
	item: FlattenedEnrichedItem | undefined,
): EnrichedItem<FlatItem>[] => {
	const ancestors: EnrichedItem<FlatItem>[] = [];

	if (item) {
		let currentParentId: ItemId | string | undefined = item.meta.parentId;
		const startingIndex = item.flatIndex - 1;

		// we can iterate through the earlier objects to find all the ancestors
		for (let i = startingIndex; i >= 0 && currentParentId; i--) {
			// had to introduce this 'currentItem' variable here to overcome typescript error
			const currentItem = items[i];
			if (currentItem.type === ITEM_TYPE && currentItem.item.id === currentParentId) {
				ancestors.push(currentItem);
				currentParentId = items[i].meta.parentId;
			}
		}
	}
	return ancestors.reverse();
};

/**
 * Returns the create item
 * @param {*} items
 * @param {*} createItemIndex
 */
export const getCreateItem = (
	items: FlattenedEnrichedItem[],
	createItemIndex: number,
): EnrichedCreateItem<FlatItem> | undefined => {
	const createItem = items[createItemIndex];
	if (createItem === undefined || createItem.type !== CREATE_ITEM_TYPE) {
		return undefined;
	}
	return createItem;
};

export const getVisibleItemsFromIndexes = ({
	startIndex,
	endIndex,
	itemHeights,
	createItemIndex,
	items,
	overscan = OVERSCAN_COUNT,
}: {
	startIndex: number;
	endIndex: number;
	itemHeights: number[];
	createItemIndex: number;
	items: FlattenedEnrichedItem[];
	overscan?: number;
}): State => {
	// Overscan indexes
	const totalCount = itemHeights.length;
	const overscanStart = Math.max(startIndex - overscan, 0);
	const overscanEnd = Math.min(endIndex + overscan, totalCount - 1);

	let createPosition;

	// Check if create-item is rendered before visible items
	if (createItemIndex > -1 && createItemIndex < overscanStart) {
		createPosition = TOP;

		// Check if create-item is rendered after visible items
	} else if (createItemIndex > overscanEnd) {
		createPosition = BOTTOM;
	}

	const createItem = getCreateItem(items, createItemIndex);

	// the ancestors of the first visible item are invisible.
	// this is needed to make sure all the children of the invisible ancestors are rendered
	const invisibleAncestors = getAncestors(items, items[overscanStart]);

	// we need to keep the createItem and its ancestors always in the rendered list
	// this is to prevent the createItem from being rendered when it enters in the visible range
	// If we render the createItem everytime by scrolling,
	// it will cause a scroll jump to the createItem input fields which is a bad user xp
	// so, we keep it always rendered to stop this scroll jump

	// if the createItem is in the visible range, its parents are included in invisibleAncestors,
	// otherwise we should double-check the createItemAncestors
	const createItemAncestors: EnrichedItem<FlatItem>[] | undefined =
		createPosition && createItem ? getAncestors(items, createItem) : [];

	const requiredItems = items.filter(
		(item, index) =>
			(index >= overscanStart && index <= overscanEnd) ||
			// we need to keep the createItem always in the rendered list
			index === createItemIndex ||
			// to fix type error
			(item.type === ITEM_TYPE &&
				// we need to render the create item if it is active, even it is beyond the visible items
				(createItemAncestors?.includes(item) ||
					// the invisible ancestors are needed for their children to get rendered
					invisibleAncestors?.includes(item))),
	);

	return {
		requiredItems,
		overscanRange: { start: overscanStart, end: overscanEnd },
		overscan,
	};
};
