import { useCallback, useEffect, useState } from 'react';

import debounce from 'lodash/debounce';

import { RESIZE_DEBOUNCE_MS } from '../../../../../common/constants';
import { useViewport } from '../../../../../common/context/viewport/context';

import { getMaxListWidth } from './utils';

/** The list's maximum width is relative to the table width. Since the table width is dynamic,
 * and influenced by external factors (e.g. screen resolution), it cannot be known ahead of render.
 * Thus, the value returned here is used constrain the current list width, either retroactively,
 * or during a resize operation.
 */
const useMaxWidth = () => {
	const [maxWidth, setMaxWidth] = useState(Infinity);
	const { requestViewportBoundingClient } = useViewport();

	const setMaxWidthFromViewport = useCallback(() => {
		const viewportBoundingClient = requestViewportBoundingClient({ withTimelineOffset: false });

		if (viewportBoundingClient) {
			const newMaxWidth = getMaxListWidth(viewportBoundingClient.width);
			setMaxWidth(newMaxWidth);
		}
	}, [requestViewportBoundingClient]);

	useEffect(() => {
		setMaxWidthFromViewport();
	}, [setMaxWidthFromViewport]);

	useEffect(() => {
		const onWindowResize = debounce(() => {
			setMaxWidthFromViewport();
		}, RESIZE_DEBOUNCE_MS);

		// eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
		window.addEventListener('resize', onWindowResize);

		return () => {
			// eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
			window.removeEventListener('resize', onWindowResize);
			onWindowResize.cancel();
		};
	}, [setMaxWidthFromViewport]);

	return maxWidth;
};

export { useMaxWidth };
