/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { memo } from 'react';

import { css, jsx } from '@compiled/react';

import { zIndex } from '../../../../common/constants';
import { useIsTableEmpty } from '../../../../common/context/items';
import { useListWidth } from '../../../../common/context/list';
import { useTimelineState } from '../../../../common/context/timeline';
import { secondaryBorderColor } from '../../../../common/styled/timeline';
import type { RenderListEmptyContent } from '../../../../renderers';

const listEmptyContentStyles = css({
	position: 'sticky',
	left: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.LIST,
});

const bottomBorderContainerStyles = css({
	position: 'relative',
	display: 'flex',
	width: '100%',
	height: '1px',
});

const listBottomBorderStyles = css({
	position: 'sticky',
	flex: '0 0 auto',
	left: 0,
	height: '1px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	backgroundColor: secondaryBorderColor,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.LIST,
});

const chartBottomBorderStyles = css({
	position: 'relative',
	flex: '0 0 auto',
	height: '1px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	backgroundColor: secondaryBorderColor,
});

export type Props = {
	renderListEmptyContent: RenderListEmptyContent;
};

const Footer = ({ renderListEmptyContent }: Props) => {
	const [isTableEmpty] = useIsTableEmpty();
	const [{ timelineWidth }] = useTimelineState();
	const [listWidth] = useListWidth();

	return isTableEmpty ? (
		<div css={listEmptyContentStyles} style={{ width: `${listWidth}px` }}>
			{renderListEmptyContent()}
		</div>
	) : (
		<div css={bottomBorderContainerStyles}>
			<div css={listBottomBorderStyles} style={{ width: `${listWidth}px` }} />
			<div css={chartBottomBorderStyles} style={{ width: `${timelineWidth}px` }} />
		</div>
	);
};

export default memo<Props>(Footer);
