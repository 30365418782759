import { fg } from '@atlassian/jira-feature-gating';
import { defineMessages, type MessageDescriptor } from '@atlassian/jira-intl';

export default defineMessages({
	schedulingSection: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.scheduling-section',
		defaultMessage: 'Scheduling',
		description: 'Label for scheduling section in the list item',
	},
	itemsSection: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.items-section',
		defaultMessage: 'Issues',
		description: 'Label for issues section in the list item',
	},
	createItem: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.create-item',
		defaultMessage: 'Create issue',
		description: 'Label for the create issue option in the list item menu',
	},
	createChildItem: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.create-child-item',
		defaultMessage: 'Create child issue',
		description: 'Label for the create child issue option in the list item menu',
	},
	createItemAbove: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.create-item-above',
		defaultMessage: 'Create issue above',
		description: 'Label for the create issue above option in the list item menu',
	},
	createItemBelow: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.create-item-below',
		defaultMessage: 'Create issue below',
		description: 'Label for the create issue below option in the list item menu',
	},
	moveItem: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.move-item',
		defaultMessage: 'Move issue',
		description: 'Label for the move issue option in the list item menu',
	},
	moveItemUp: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.move-item-up',
		defaultMessage: 'Move issue up',
		description: 'Label for the move issue up option in the list item menu',
	},
	moveItemDown: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.move-item-down',
		defaultMessage: 'Move issue down',
		description: 'Label for the move issue down option in the list item menu',
	},
	moveItemToTop: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.move-item-to-top',
		defaultMessage: 'Move issue to top',
		description: 'Label for the move issue to top option in the list item menu',
	},
	moveItemToBottom: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.move-item-to-bottom',
		defaultMessage: 'Move issue to bottom',
		description: 'Label for the move issue to bottom option in the list item menu',
	},
	selectedItemCounter: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.selected-item-counter',
		defaultMessage:
			'<badge>{count}</badge> {count, plural, one {issue selected} other {issues selected}}',
		description: 'Label for the selected item counter in the list item menu',
	},
	itemsSectionIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.items-section-issue-term-refresh',
		defaultMessage: 'Work items',
		description: 'Label for issues section in the list item',
	},
	createItemIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.create-item-issue-term-refresh',
		defaultMessage: 'Create',
		description: 'Label for the create issue option in the list item menu',
	},
	createChildItemIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.create-child-item-issue-term-refresh',
		defaultMessage: 'Create child work item',
		description: 'Label for the create child issue option in the list item menu',
	},
	createItemAboveIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.create-item-above-issue-term-refresh',
		defaultMessage: 'Create work item above',
		description: 'Label for the create issue above option in the list item menu',
	},
	createItemBelowIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.create-item-below-issue-term-refresh',
		defaultMessage: 'Create work item below',
		description: 'Label for the create issue below option in the list item menu',
	},
	moveItemIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.move-item-issue-term-refresh',
		defaultMessage: 'Move work item',
		description: 'Label for the move issue option in the list item menu',
	},
	moveItemUpIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.move-item-up-issue-term-refresh',
		defaultMessage: 'Move work item up',
		description: 'Label for the move issue up option in the list item menu',
	},
	moveItemDownIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.move-item-down-issue-term-refresh',
		defaultMessage: 'Move work item down',
		description: 'Label for the move issue down option in the list item menu',
	},
	moveItemToTopIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.move-item-to-top-issue-term-refresh',
		defaultMessage: 'Move work item to top',
		description: 'Label for the move issue to top option in the list item menu',
	},
	moveItemToBottomIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.move-item-to-bottom-issue-term-refresh',
		defaultMessage: 'Move work item to bottom',
		description: 'Label for the move issue to bottom option in the list item menu',
	},
	selectedItemCounterIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.state.selectors.list-item-menu-option.pure.selected-item-counter-issue-term-refresh',
		defaultMessage:
			'<badge>{count}</badge> {count, plural, one {work item selected} other {work items selected}}',
		description: 'Label for the selected item counter in the list item menu',
	},
});
export const messageWithIssueTerminologyRefresh = (
	messages: Record<string, MessageDescriptor>,
	key: string,
): MessageDescriptor => {
	const refreshKey = `${key}IssueTermRefresh`;
	if (refreshKey in messages && fg('jira-issue-terminology-refresh-m3')) {
		return messages[refreshKey];
	}
	return messages[key];
};
