import { fg } from '@atlassian/jira-feature-gating';

import { listItemMenu } from '@atlassian/timeline-table/common/constants';

import type { ListItemMenuOptions } from '@atlassian/timeline-table/common/types/item';
import {
	BULK_SECTION,
	SCHEDULING_SECTION,
	REMOVE_DATES,
	EDIT_SPRINT,
	EDIT_DATES,
	BULK_EDIT_DATES,
	EDIT_DEPENDENCY,
	CHANGE_PARENT_ISSUE,
	CHANGE_ISSUE_COLOR,
} from '../../../../constants.tsx';
import messages, { messageWithIssueTerminologyRefresh } from './messages.tsx';

export const getListItemMenuOptionsPure = (
	canUserEdit: boolean,
	isSprintsFeatureEnabled: boolean,
	isChildIssuePlanningEnabled: boolean,
): ListItemMenuOptions => {
	const {
		ITEMS_SECTION,
		MOVE_ITEM,
		CREATE_ITEM,
		CREATE_CHILD_ITEM,
		CREATE_ITEM_ABOVE,
		CREATE_ITEM_BELOW,
		MOVE_ITEM_UP,
		MOVE_ITEM_DOWN,
		MOVE_ITEM_TO_TOP,
		MOVE_ITEM_TO_BOTTOM,
		SELECTED_ITEM_COUNTER,
	} = listItemMenu;
	const overrideLabels = {
		[ITEMS_SECTION]: messageWithIssueTerminologyRefresh(messages, ITEMS_SECTION),
		[CREATE_ITEM]: messageWithIssueTerminologyRefresh(messages, CREATE_ITEM),
		[MOVE_ITEM]: messageWithIssueTerminologyRefresh(messages, MOVE_ITEM),
		[CREATE_CHILD_ITEM]: messageWithIssueTerminologyRefresh(messages, CREATE_CHILD_ITEM),
		[CREATE_ITEM_ABOVE]: messageWithIssueTerminologyRefresh(messages, CREATE_ITEM_ABOVE),
		[CREATE_ITEM_BELOW]: messageWithIssueTerminologyRefresh(messages, CREATE_ITEM_BELOW),
		[MOVE_ITEM_UP]: messageWithIssueTerminologyRefresh(messages, MOVE_ITEM_UP),
		[MOVE_ITEM_DOWN]: messageWithIssueTerminologyRefresh(messages, MOVE_ITEM_DOWN),
		[MOVE_ITEM_TO_TOP]: messageWithIssueTerminologyRefresh(messages, MOVE_ITEM_TO_TOP),
		[MOVE_ITEM_TO_BOTTOM]: messageWithIssueTerminologyRefresh(messages, MOVE_ITEM_TO_BOTTOM),
		[SELECTED_ITEM_COUNTER]: messageWithIssueTerminologyRefresh(messages, SELECTED_ITEM_COUNTER),
	};

	if (fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y')) {
		if (canUserEdit) {
			const schedulingOptions = [
				...(isSprintsFeatureEnabled && isChildIssuePlanningEnabled ? [{ id: EDIT_SPRINT }] : []),
				{ id: EDIT_DATES },
				{ id: REMOVE_DATES },
				{ id: EDIT_DEPENDENCY },
			];

			return {
				overrideLabels,
				customSections: [
					{
						id: ITEMS_SECTION,
						title: messageWithIssueTerminologyRefresh(messages, ITEMS_SECTION),
						options: [{ id: CHANGE_PARENT_ISSUE }, { id: CHANGE_ISSUE_COLOR }],
					},
					{
						id: SCHEDULING_SECTION,
						title: messages[SCHEDULING_SECTION],
						options: schedulingOptions,
					},
				],
				customSectionsBulk: [
					{
						id: BULK_SECTION,
						options: [
							...(fg('jsw_roadmap_timeline_meatball_selected_item_option')
								? [{ id: BULK_EDIT_DATES }]
								: []),
						],
					},
				],
			};
		}
	}

	return {
		overrideLabels,
		customSections: [],
		customSectionsBulk: [],
	};
};
