import React from 'react';
import Toolbar from '@atlassian/jira-aais-timeline-toolbar/src/ui/container/index.tsx';
import type {
	ToolbarItem,
	ToolbarItemProps,
} from '@atlassian/jira-aais-timeline-toolbar/src/ui/container/types.tsx';
import FullscreenButton from '@atlassian/jira-fullscreen-button/src/index.tsx';
import { JiraBottomRightCornerOutlet } from '@atlassian/jira-layout-controller/src/ui/bottom-right-corner/outlet/index.tsx';
import { getWillShowNav3 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav3.tsx';
import { RoadmapTodayButton } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-overlay/today-button/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import Legend from './legend/index.tsx';
import TimelineMode from './timeline-mode/index.tsx';

type Props = {
	isMacroView: boolean;
};

// NOTE: We are confident that the queried elements with the given id will always be HTMLElement | null
const getFullscreenRefs = (): (HTMLElement | null)[] =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, jira/jira-ssr/no-unchecked-globals-usage
	Array.from(document.querySelectorAll('[data-fullscreen-id]')) as (HTMLElement | null)[];

const OverlayButton = ({ isMacroView }: Props) => {
	const renderToday = ({ isHidden }: ToolbarItemProps) => (
		<RoadmapTodayButton isHidden={isHidden} />
	);

	const renderModeSwitcher = ({ isHidden }: ToolbarItemProps) => (
		<TimelineMode isHidden={isHidden} />
	);

	const renderLegend = ({ isHidden }: ToolbarItemProps) => <Legend isHidden={isHidden} />;

	const renderFullscreenButton = ({ isHidden }: ToolbarItemProps) => (
		<FullscreenButton
			testId="roadmap.standard-roadmap.common.table.chart-overlay.overlay-button.fullscreen-button"
			getCustomRefs={getFullscreenRefs}
			withContainerStyles={false}
			isSubtle={isVisualRefreshEnabled()}
			isHidden={isHidden}
		/>
	);

	const toolbarItems: ToolbarItem[] = [
		{
			id: 'today-button',
			element: fg('jsw_roadmaps_timeline-fix-a11y-rain') ? renderToday : <RoadmapTodayButton />,
			showDivider: false,
		},
		{
			id: 'timeline-mode-switcher',
			element: fg('jsw_roadmaps_timeline-fix-a11y-rain') ? renderModeSwitcher : <TimelineMode />,
		},
		{
			id: 'legend',
			element: fg('jsw_roadmaps_timeline-fix-a11y-rain') ? renderLegend : <Legend />,
		},
	];

	getWillShowNav3() &&
		toolbarItems.push({
			id: 'fullscreen-button',
			element: fg('jsw_roadmaps_timeline-fix-a11y-rain') ? (
				renderFullscreenButton
			) : (
				<FullscreenButton
					testId="roadmap.standard-roadmap.common.table.chart-overlay.overlay-button.fullscreen-button"
					getCustomRefs={getFullscreenRefs}
					withContainerStyles={false}
					isSubtle={isVisualRefreshEnabled()}
				/>
			),
			isVisible: !isMacroView,
			showDivider: false,
		});

	return (
		<JiraBottomRightCornerOutlet orderFromRight={2} id="software-roadmap-timeline-overlay">
			<Toolbar items={toolbarItems} />
		</JiraBottomRightCornerOutlet>
	);
};

export { OverlayButton };
