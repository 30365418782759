import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import type { ColumnDuration, TimelineDuration } from '../../../common/types/timeline';
import { getColumnPositions, getTodayColumnIndex } from '../../../common/utils/columns';

import Columns from './view';

type Props = {
	isHighlightToday: boolean;
	today: number;
	timelineDuration: TimelineDuration;
	columnDurations: ColumnDuration[];
	// Remove optional when cleaning up fg('platform-component-visual-refresh')
	isOverlayInHeader?: boolean;
	// Remove optional when cleaning up fg('platform-component-visual-refresh')
	subheaderHeight?: number;
};

const ColumnsOverlay = ({
	isHighlightToday,
	today,
	timelineDuration,
	columnDurations,
	isOverlayInHeader,
	subheaderHeight,
}: Props) => (
	<Columns
		isHighlightToday={isHighlightToday}
		columnPositions={getColumnPositions(timelineDuration, columnDurations)}
		todayColumnIndex={getTodayColumnIndex(today, columnDurations)}
		{...(fg('platform-component-visual-refresh') ? { isOverlayInHeader, subheaderHeight } : {})}
	/>
);

export default ColumnsOverlay;
export type { Props as APIProps };
