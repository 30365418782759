/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * @jsxRuntime classic
 * @jsx jsx
 * @jsxFrag
 */
import React, {
	forwardRef,
	type MouseEvent,
	type Ref,
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';

import { css, jsx } from '@compiled/react';
import invariant from 'tiny-invariant';

import {
	draggable,
	type ElementEventBasePayload,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { disableNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview';
import { preventUnhandled } from '@atlaskit/pragmatic-drag-and-drop/prevent-unhandled';
import { token } from '@atlaskit/tokens';

import { zIndex } from '../../../common/constants';

import { DEFAULT_DRAG_CACHE, SCROLLBAR_OFFSET, SCROLLBAR_SIZE } from './constants';
import type { DragCache, ImperativeRef, OnSetScroll } from './types';
import {
	getMemoisedThumbState,
	getRelativeScrollPosition,
	getThumbPosition,
	getThumbSize,
} from './utils';

const baseTrackStyles = css({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${SCROLLBAR_SIZE}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.SCROLLBAR,
	'&:hover': {
		transition: 'background 0.1s ease-in',
		backgroundColor: token('color.background.neutral.pressed', 'rgba(0,0,0,0.3)'),
	},
	'&:active': {
		backgroundColor: token('color.background.neutral.pressed', 'rgba(0,0,0,0.3)'),
	},
});

const baseThumbStyles = css({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${SCROLLBAR_SIZE}px`,
	backgroundColor: token('color.interaction.pressed', 'rgba(0,0,0,0.4)'),
});

const visibleStyles = css({
	visibility: 'visible',
});

const notVisibleStyles = css({
	visibility: 'hidden',
});

const verticalTrackStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	bottom: `${SCROLLBAR_OFFSET}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${SCROLLBAR_SIZE}px`,
	right: 0,
});

const horizontalTrackStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	right: `${SCROLLBAR_OFFSET}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${SCROLLBAR_SIZE}px`,
	bottom: 0,
});

const verticalThumbStyles = css({
	width: '100%',
});

const horizontalThumbStyles = css({
	height: '100%',
});

const useImperativeTracksAndThumbs = ({
	mainComponentRef,
}: {
	mainComponentRef: Ref<ImperativeRef>;
}): {
	horizontalTrackRef: React.RefObject<HTMLDivElement>;
	horizontalThumbRef: React.RefObject<HTMLDivElement>;
	verticalTrackRef: React.RefObject<HTMLDivElement>;
	verticalThumbRef: React.RefObject<HTMLDivElement>;
	horizontalThumbSize: number;
	verticalThumbSize: number;
} => {
	const horizontalTrackRef = useRef<HTMLDivElement>(null);
	const horizontalThumbRef = useRef<HTMLDivElement>(null);

	const verticalTrackRef = useRef<HTMLDivElement>(null);
	const verticalThumbRef = useRef<HTMLDivElement>(null);

	// State updates are not batched outside React synthetic events so we consolidate the data here
	const [{ horizontalThumbSize, verticalThumbSize }, setThumbSizes] = useState({
		horizontalThumbSize: 0,
		verticalThumbSize: 0,
	});

	const setHorizontalThumbPosition = useCallback(
		(trackSize: number, scrollRatio: number, thumbSize: number) => {
			if (scrollRatio >= 0) {
				const position = getThumbPosition(scrollRatio, trackSize, thumbSize);

				if (horizontalThumbRef.current) {
					horizontalThumbRef.current.style.transform = `translateX(${position}px)`;
				}
			}
		},
		[],
	);

	const setVerticalThumbPosition = useCallback(
		(trackSize: number, scrollRatio: number, thumbSize: number) => {
			if (scrollRatio >= 0) {
				const position = getThumbPosition(scrollRatio, trackSize, thumbSize);

				if (verticalThumbRef.current) {
					verticalThumbRef.current.style.transform = `translateY(${position}px)`;
				}
			}
		},
		[],
	);
	useImperativeHandle(
		mainComponentRef,
		() => ({
			setThumbPositions: ({ horizontalScrollRatio, verticalScrollRatio }) => {
				if (!horizontalTrackRef.current || !verticalTrackRef.current) {
					throw new Error('An element required to set the thumb positions were null');
				}

				// DOM reads //
				const { clientWidth } = horizontalTrackRef.current;
				const { clientHeight } = verticalTrackRef.current;

				// DOM writes //
				setHorizontalThumbPosition(clientWidth, horizontalScrollRatio, horizontalThumbSize);
				setVerticalThumbPosition(clientHeight, verticalScrollRatio, verticalThumbSize);
			},
			update: (
				{ horizontalScrollRatio, verticalScrollRatio },
				{ horizontalContentRatio, verticalContentRatio },
			) => {
				if (!horizontalTrackRef.current || !verticalTrackRef.current) {
					throw new Error('An element required to update the thumb tracks were null');
				}

				// DOM reads //
				const { clientWidth } = horizontalTrackRef.current;
				const { clientHeight } = verticalTrackRef.current;

				let hThumbSize = 0;
				let vThumbSize = 0;

				// DOM writes //
				if (horizontalContentRatio < 1) {
					hThumbSize = getThumbSize(horizontalContentRatio, clientWidth);
					setHorizontalThumbPosition(clientWidth, horizontalScrollRatio, hThumbSize);
				}

				if (verticalContentRatio < 1) {
					vThumbSize = getThumbSize(verticalContentRatio, clientHeight);
					setVerticalThumbPosition(clientHeight, verticalScrollRatio, vThumbSize);
				}

				setThumbSizes(getMemoisedThumbState(hThumbSize, vThumbSize));
			},
		}),
		[horizontalThumbSize, verticalThumbSize, setHorizontalThumbPosition, setVerticalThumbPosition],
	);

	return {
		horizontalThumbRef,
		horizontalTrackRef,
		verticalTrackRef,
		verticalThumbRef,
		horizontalThumbSize,
		verticalThumbSize,
	};
};

// Stop propogation of mouse down event from thumb to track in order to
// prevent jumping of thumb to click point of track "beneath" the thumb.
const handleThumbMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
	event.stopPropagation();
};

type Props = {
	isHideScrollbar: boolean;
	listWidth: number;
	timelineTop: number;
	onSetScrollLeft: OnSetScroll;
	onSetScrollTop: OnSetScroll;
};

/* This component is responsible for displaying the custom track and thumb.
 *
 * Responding to user actions (e.g. clicking or dragging) are handled internally, while syncing with changes from external
 * sources (e.g. window resizes) are managed by the parent component via a custom ref. The track dimensions are not a 1:1
 * recreation of the real track, since it is only displayed over the timeline, and so all parameters from the parent are
 * "viewport relative" and translated internally to "track relative" coordinate system.
 */
const CustomScrollbarThumbTracks = (
	{ listWidth, isHideScrollbar, timelineTop, onSetScrollLeft, onSetScrollTop }: Props,
	ref: Ref<ImperativeRef>,
) => {
	const dragCache = useRef<DragCache>(DEFAULT_DRAG_CACHE);

	// ========================= //
	// === IMPERATIVE EFFECT === //
	// ========================= //

	const {
		horizontalThumbRef,
		horizontalTrackRef,
		verticalTrackRef,
		verticalThumbRef,
		horizontalThumbSize,
		verticalThumbSize,
	} = useImperativeTracksAndThumbs({ mainComponentRef: ref });

	// ===================================== //
	// ==== EVENT HANDLING - HORIZONTAL ==== //
	// ===================================== //

	const onStartDragHorizontalThumb = useCallback(
		({ location }: ElementEventBasePayload) => {
			if (!horizontalTrackRef.current || !horizontalThumbRef.current) {
				throw new Error('An element required to drag the horizontal scroll thumb was null');
			}

			const { left, width } = horizontalTrackRef.current.getBoundingClientRect();
			const thumbClientRect = horizontalThumbRef.current.getBoundingClientRect();
			const relativeMousePosition = location.initial.input.clientX - thumbClientRect.left;

			dragCache.current = {
				thumbOrigin: Math.round(horizontalThumbSize / 2 - relativeMousePosition),
				trackPosition: left,
				trackSize: width,
			};
		},
		[horizontalThumbRef, horizontalThumbSize, horizontalTrackRef],
	);

	const onDragHorizontalThumb = useCallback(
		({ location }: ElementEventBasePayload) => {
			const { thumbOrigin, trackPosition, trackSize } = dragCache.current;

			const relativeScrollPosition = getRelativeScrollPosition({
				thumbPosition: thumbOrigin + location.current.input.clientX,
				thumbSize: horizontalThumbSize,
				trackPosition,
				trackSize,
			});

			onSetScrollLeft(relativeScrollPosition);
		},
		[horizontalThumbSize, onSetScrollLeft],
	);

	const onMouseDownHorizontalTrack = (event: MouseEvent<HTMLDivElement>) => {
		const { left, width } = event.currentTarget.getBoundingClientRect();

		const relativeScrollPosition = getRelativeScrollPosition({
			thumbPosition: event.pageX,
			thumbSize: horizontalThumbSize,
			trackPosition: left,
			trackSize: width,
		});

		onSetScrollLeft(relativeScrollPosition);
	};

	// =================================== //
	// ==== EVENT HANDLING - VERTICAL ==== //
	// =================================== //

	const onMouseDownVerticalTrack = (event: MouseEvent<HTMLDivElement>) => {
		const { top, height } = event.currentTarget.getBoundingClientRect();

		const relativeScrollPosition = getRelativeScrollPosition({
			thumbPosition: event.pageY,
			thumbSize: verticalThumbSize,
			trackPosition: top,
			trackSize: height,
		});

		onSetScrollTop(relativeScrollPosition);
	};

	const onStartDragVerticalThumb = useCallback(
		({ location }: ElementEventBasePayload) => {
			if (!verticalTrackRef.current || !verticalThumbRef.current) {
				throw new Error('An element required to drag the vertical scroll thumb was null');
			}

			const { top, height } = verticalTrackRef.current.getBoundingClientRect();
			const thumbClientRect = verticalThumbRef.current.getBoundingClientRect();
			const relativeMousePosition = location.initial.input.clientY - thumbClientRect.top;

			dragCache.current = {
				thumbOrigin: Math.round(verticalThumbSize / 2 - relativeMousePosition),
				trackPosition: top,
				trackSize: height,
			};
		},
		[verticalThumbRef, verticalThumbSize, verticalTrackRef],
	);

	const onDragVerticalThumb = useCallback(
		({ location }: ElementEventBasePayload) => {
			const { thumbOrigin, trackPosition, trackSize } = dragCache.current;

			const relativeScrollPosition = getRelativeScrollPosition({
				thumbPosition: thumbOrigin + location.current.input.clientY,
				thumbSize: verticalThumbSize,
				trackPosition,
				trackSize,
			});

			onSetScrollTop(relativeScrollPosition);
		},
		[verticalThumbSize, onSetScrollTop],
	);

	// ================ //
	// ==== EFFECTS ==== //
	// ================ //

	useEffect(() => {
		const horizontalThumb = horizontalThumbRef.current;
		invariant(horizontalThumb);

		return draggable({
			element: horizontalThumb,
			onGenerateDragPreview: ({ nativeSetDragImage }) => {
				disableNativeDragPreview({ nativeSetDragImage });
				preventUnhandled.start();
			},
			onDragStart: onStartDragHorizontalThumb,
			onDrag: onDragHorizontalThumb,
		});
	}, [onStartDragHorizontalThumb, onDragHorizontalThumb, horizontalThumbRef]);

	useEffect(() => {
		const verticalThumb = verticalThumbRef.current;
		invariant(verticalThumb);

		return draggable({
			element: verticalThumb,
			onGenerateDragPreview: ({ nativeSetDragImage }) => {
				disableNativeDragPreview({ nativeSetDragImage });
				preventUnhandled.start();
			},
			onDragStart: onStartDragVerticalThumb,
			onDrag: onDragVerticalThumb,
		});
	}, [onStartDragVerticalThumb, onDragVerticalThumb, verticalThumbRef]);

	// ================ //
	// ==== RENDER ==== //
	// ================ //

	return (
		<>
			<div
				ref={horizontalTrackRef}
				data-testid="roadmap.timeline-table.main.custom-scrollbars.thumb-tracks.horizontal-track"
				css={[
					baseTrackStyles,
					horizontalThumbSize > 0 && !isHideScrollbar ? visibleStyles : notVisibleStyles,
					horizontalTrackStyles,
				]}
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					left: `${listWidth + SCROLLBAR_OFFSET}px`,
				}}
				onMouseDown={onMouseDownHorizontalTrack}
			>
				<div
					data-testid="roadmap.timeline-table.main.custom-scrollbars.thumb-tracks.horizontal-thumb"
					css={[baseThumbStyles, horizontalThumbStyles]}
					ref={horizontalThumbRef}
					onMouseDown={handleThumbMouseDown}
					style={{
						width: `${horizontalThumbSize}px`,
					}}
				/>
			</div>
			<div
				ref={verticalTrackRef}
				data-testid="roadmap.timeline-table.main.custom-scrollbars.thumb-tracks.vertical-track"
				css={[
					baseTrackStyles,
					verticalThumbSize > 0 && !isHideScrollbar ? visibleStyles : notVisibleStyles,
					verticalTrackStyles,
				]}
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					top: `${timelineTop + SCROLLBAR_OFFSET}px`,
				}}
				onMouseDown={onMouseDownVerticalTrack}
			>
				<div
					data-testid="roadmap.timeline-table.main.custom-scrollbars.thumb-tracks.vertical-thumb"
					css={[baseThumbStyles, verticalThumbStyles]}
					ref={verticalThumbRef}
					onMouseDown={handleThumbMouseDown}
					style={{
						height: `${verticalThumbSize}px`,
					}}
				/>
			</div>
		</>
	);
};

export default forwardRef<ImperativeRef, Props>(CustomScrollbarThumbTracks);
