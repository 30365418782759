import { defineMessages } from 'react-intl-next';

const messages = defineMessages({
	today: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.today',
		defaultMessage: 'Today is {date}',
		description:
			'Aria label for the header grid cell that contains the today line the project timeline. Reads out the current date.',
	},
	january: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.january',
		defaultMessage: 'January',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Header for the month of January',
	},
	february: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.february',
		defaultMessage: 'February',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Header for the month of February',
	},
	march: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.march',
		defaultMessage: 'March',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Header for the month of March',
	},
	april: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.april',
		defaultMessage: 'April',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Header for the month of April',
	},
	may: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.may',
		defaultMessage: 'May',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Header for the month of May',
	},
	june: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.june',
		defaultMessage: 'June',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Header for the month of June',
	},
	july: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.july',
		defaultMessage: 'July',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Header for the month of July',
	},
	august: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.august',
		defaultMessage: 'August',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Header for the month of August',
	},
	september: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.september',
		defaultMessage: 'September',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Header for the month of September',
	},
	october: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.october',
		defaultMessage: 'October',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Header for the month of October',
	},
	november: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.november',
		defaultMessage: 'November',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Header for the month of November',
	},
	december: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.december',
		defaultMessage: 'December',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Header for the month of December',
	},
	januaryOld: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.january-old',
		defaultMessage: 'Jan',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of January',
	},
	februaryOld: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.february-old',
		defaultMessage: 'Feb',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of February',
	},
	marchOld: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.march-old',
		defaultMessage: 'Mar',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of March',
	},
	aprilOld: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.april-old',
		defaultMessage: 'Apr',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of April',
	},
	mayOld: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.may-old',
		defaultMessage: 'May',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of May',
	},
	juneOld: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.june-old',
		defaultMessage: 'Jun',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of June',
	},
	julyOld: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.july-old',
		defaultMessage: 'Jul',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of July',
	},
	augustOld: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.august-old',
		defaultMessage: 'Aug',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of August',
	},
	septemberOld: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.september-old',
		defaultMessage: 'Sep',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of September',
	},
	octoberOld: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.october-old',
		defaultMessage: 'Oct',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of October',
	},
	novemberOld: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.november-old',
		defaultMessage: 'Nov',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of November',
	},
	decemberOld: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.december-old',
		defaultMessage: 'Dec',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of December',
	},
	monday: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.monday',
		defaultMessage: 'M',
		description:
			'Column sub-heading text. High fidelity visual guide for a users relative position timeline. Proper noun. Abbreviation for the day of Monday',
	},
	tuesday: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.tuesday',
		defaultMessage: 'T',
		description:
			'Column sub-heading text. High fidelity visual guide for a users relative position timeline. Proper noun. Abbreviation for the day of Tuesday',
	},
	wednesday: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.wednesday',
		defaultMessage: 'W',
		description:
			'Column sub-heading text. High fidelity visual guide for a users relative position timeline. Proper noun. Abbreviation for the day of Wednesday',
	},
	thursday: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.thursday',
		defaultMessage: 'T',
		description:
			'Column sub-heading text. High fidelity visual guide for a users relative position timeline. Proper noun. Abbreviation for the day of Thursday',
	},
	friday: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.friday',
		defaultMessage: 'F',
		description:
			'Column sub-heading text. High fidelity visual guide for a users relative position timeline. Proper noun. Abbreviation for the day of Friday',
	},
	saturday: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.saturday',
		defaultMessage: 'S',
		description:
			'Column sub-heading text. High fidelity visual guide for a users relative position timeline. Proper noun. Abbreviation for the day of Saturday',
	},
	sunday: {
		id: 'roadmap.timeline-table.main.header.chart.calendar-cells.sunday',
		defaultMessage: 'S',
		description:
			'Column sub-heading text. High fidelity visual guide for a users relative position timeline. Proper noun. Abbreviation for the day of Sunday',
	},
});

const inWeekOrder = [
	messages.monday,
	messages.tuesday,
	messages.wednesday,
	messages.thursday,
	messages.friday,
	messages.saturday,
	messages.sunday,
];

const inMonthOrder = [
	messages.january,
	messages.february,
	messages.march,
	messages.april,
	messages.may,
	messages.june,
	messages.july,
	messages.august,
	messages.september,
	messages.october,
	messages.november,
	messages.december,
];

const inMonthOrderOld = [
	messages.januaryOld,
	messages.februaryOld,
	messages.marchOld,
	messages.aprilOld,
	messages.mayOld,
	messages.juneOld,
	messages.julyOld,
	messages.augustOld,
	messages.septemberOld,
	messages.octoberOld,
	messages.novemberOld,
	messages.decemberOld,
];

const inQuarterOrder = [
	{ startMonth: messages.january, endMonth: messages.march },
	{ startMonth: messages.april, endMonth: messages.june },
	{ startMonth: messages.july, endMonth: messages.september },
	{ startMonth: messages.october, endMonth: messages.december },
];

const inQuarterOrderOld = [
	{ startMonth: messages.januaryOld, endMonth: messages.marchOld },
	{ startMonth: messages.aprilOld, endMonth: messages.juneOld },
	{ startMonth: messages.julyOld, endMonth: messages.septemberOld },
	{ startMonth: messages.octoberOld, endMonth: messages.decemberOld },
];

export default messages;
export { inWeekOrder, inMonthOrder, inQuarterOrder, inMonthOrderOld, inQuarterOrderOld };
