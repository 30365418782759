import { memo, useEffect, useMemo, useRef } from 'react';

import difference from 'lodash/difference';
import uniq from 'lodash/fp/uniq';
import isEqual from 'lodash/isEqual';

import { useActiveItemId, useSelectedItemIds } from '../../common/context/items';
import { useSideEffectMarshal } from '../../common/context/side-effect-marshal';

/* Item highlight styles for selected & active states are orchestrated via the style marshal
 * since it has to be synchronised across a row. As such, there is no need for an item
 *  component to even know about whether it is selected, allowing us to maintain the selection
 * state without going deep into the tree. This also has performance benefits.
 */
export const ItemsHighlighter = () => {
	const [selectedItemIds] = useSelectedItemIds();
	const [activeItemId] = useActiveItemId();
	const { onRowSelect, onRowDeselect } = useSideEffectMarshal();
	const highlighted = useMemo(
		() => uniq(selectedItemIds.concat(activeItemId ? [activeItemId] : [])),
		[selectedItemIds, activeItemId],
	);

	const prevHighlighted = useRef(highlighted);

	useEffect(() => {
		const toBeDehighlighted = difference(prevHighlighted.current, highlighted);
		onRowDeselect(toBeDehighlighted);
		onRowSelect(highlighted);
	}, [selectedItemIds, onRowSelect, onRowDeselect, highlighted]);

	useEffect(() => {
		prevHighlighted.current = highlighted;
	}, [highlighted]);

	return null;
};

/* Having this update unnecessarily contributes to an unexpected lifecycle in the side-effect-marshal.
 * The simplest remedy for now is to deep compare the selected item ids. In future, we may want to integrate
 * the selection into the side-effect-marshal itself.
 */
export default memo(ItemsHighlighter, isEqual);
