import React, { useCallback } from 'react';

import noop from 'lodash/noop';
import { fg } from '@atlassian/jira-feature-gating';
import TimelineTable from '@atlassian/timeline-table';
import type {
	Renderers,
	RenderListItemContent,
	RenderChartItemContent,
	RenderListItemSecondaryContent,
	RenderListItemMenuOption,
	RenderListItemModal,
	ListItemMenuOptionProps,
	ListItemModalProps,
} from '@atlassian/timeline-table/renderers';
import type { OnItemDragStart } from '@atlassian/timeline-table/common/types/callbacks';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { DRAG_DROP_UNKNOWN_FIELD_ID } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

import { renderChartHeaderItem } from './chart-header-item/index.tsx';
import ChartItemContentLite from './chart-item-content-lite/index.tsx';
import ChartItemContent from './chart-item-content/index.tsx';
import ChartOverlay from './chart-overlay/index.tsx';
import ChartTimelineOverlay from './chart-timeline-overlay/index.tsx';
import ListEmptyContent from './list-empty-content/index.tsx';
import { renderListHeaderItem } from './list-header-item/index.tsx';
import ListItemContentLite from './list-item-content-lite/index.tsx';
import ListItemContent from './list-item-content/index.tsx';
import ListItemCreate from './list-item-create/async.tsx';
import ListItemMenuOption from './list-item-menu-option/index.tsx';
import ListItemModal from './list-item-modal/index.tsx';
import ListItemSecondaryContent from './list-item-secondary-content/index.tsx';
import { marshalOptions } from './marshal/index.tsx';
import { renderProvider } from './provider/index.tsx';
import type { Props } from './types.tsx';

const renderListItemContent: RenderListItemContent = (props, { isVirtual, ...state }) => {
	if (isVirtual) {
		return <ListItemContentLite {...props} />;
	}
	return <ListItemContent {...props} {...state} />;
};

const renderListItemSecondaryContent: RenderListItemSecondaryContent = (props, { isVirtual }) =>
	isVirtual ? null : <ListItemSecondaryContent {...props} />;

const renderListItemMenuOption: RenderListItemMenuOption = (props: ListItemMenuOptionProps) => (
	<ListItemMenuOption {...props} />
);

const renderListItemModal: RenderListItemModal = (props: ListItemModalProps) => (
	<ListItemModal {...props} />
);

const renderChartItemContent: RenderChartItemContent = (props, { isVirtual, ...state }) => {
	if (isVirtual) {
		return <ChartItemContentLite {...props} />;
	}
	return <ChartItemContent {...props} {...state} />;
};

const renderers: Renderers = {
	renderListEmptyContent: () => <ListEmptyContent />,
	renderListItemContent,
	renderListItemSecondaryContent,
	renderListItemCreate: (props, state) => <ListItemCreate {...props} {...state} />,
	renderChartOverlay: () => <ChartOverlay />,
	renderChartTimelineOverlay: () => <ChartTimelineOverlay />,
	renderListItemMenuOption,
	renderListItemModal,
	renderChartItemContent,
	renderProvider,
	renderListHeaderItem,
	renderChartHeaderItem,
};

const RoadmapsTable = (props: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onItemDragStart: OnItemDragStart = fg('one_event_rules_them_all_fg')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCallback(() => {
				getUpdateAnalyticsFlowHelper().fireAnalyticsStart(DRAG_DROP_UNKNOWN_FIELD_ID, {
					analytics: createAnalyticsEvent({}),
					attributes: {
						fieldType: DRAG_DROP_UNKNOWN_FIELD_ID,
						isDragEditing: true,
					},
				});
			}, [createAnalyticsEvent])
		: noop;

	return (
		<TimelineTable
			{...props}
			scope="roadmap"
			renderers={renderers}
			marshalOptions={marshalOptions}
			{...(fg('one_event_rules_them_all_fg') ? { onItemDragStart } : {})}
		/>
	);
};

export default RoadmapsTable;
export { renderers };
