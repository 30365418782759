/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { css, jsx } from '@compiled/react';

import { fg } from '@atlaskit/platform-feature-flags';
import { Y400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { zIndex } from '../../../common/constants';
import { useHeaderState } from '../../../common/context/header';

const MARKER_LINE_WIDTH = 2;
const POINTER_WIDTH = 4;

const containerStyles = css({
	position: 'absolute',
	display: 'flex',
	top: 0,
	bottom: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.TODAY_MARKER,
	pointerEvents: 'none',
});

const markerStylesOld = css({
	width: `${MARKER_LINE_WIDTH}px`,
	height: '100%',
	backgroundColor: token('color.background.accent.orange.subtle', Y400),
});

const markerStyles = css({
	width: `${MARKER_LINE_WIDTH}px`,
	height: '100%',
	backgroundColor: token('color.chart.information'),
});

const pointerStylesOld = css({
	position: 'sticky',
	width: 0,
	height: 0,
	marginLeft: `-${(MARKER_LINE_WIDTH + POINTER_WIDTH) / 2}px`,
	borderTop: `8px solid ${token('color.background.accent.orange.subtle', Y400)}`,
	borderRight: `${POINTER_WIDTH}px solid transparent`,
	borderLeft: `${POINTER_WIDTH}px solid transparent`,
});

const pointerStyles = css({
	position: 'sticky',
	width: 0,
	height: 0,
	marginLeft: `-${(MARKER_LINE_WIDTH + POINTER_WIDTH) / 2}px`,
	borderTop: `8px solid ${token('color.chart.information')}`,
	borderRight: `${POINTER_WIDTH}px solid transparent`,
	borderLeft: `${POINTER_WIDTH}px solid transparent`,
});

type Props = {
	todayPosition: number;
};

const TodayMarkerLine = ({ todayPosition }: Props) => {
	const [{ headerHeight }] = useHeaderState();

	return (
		<div
			css={containerStyles}
			data-testid="roadmap.timeline-table.main.scrollable-overlay.today-marker.container"
			style={{ left: `${todayPosition}px` }}
			aria-hidden={fg('jsw_roadmaps_timeline-fix-a11y-rain') ? true : undefined}
		>
			<div
				css={[fg('jsw_roadmaps_timeline-fix-a11y-rain') ? markerStyles : markerStylesOld]}
				role="presentation"
			>
				<span
					css={[fg('jsw_roadmaps_timeline-fix-a11y-rain') ? pointerStyles : pointerStylesOld]}
					style={{ top: `${headerHeight}px` }}
				/>
			</div>
		</div>
	);
};

export default TodayMarkerLine;
