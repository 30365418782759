import type { IntlShape, MessageDescriptor } from 'react-intl-next';

// PrimitiveType and FormatXMLElement types are reimplementations from
// previously used intl-messageformat package. That pacakge causes conflicts
// with other package VR tests, so they have been manually reimplemented here to
// remove the intl-messageformat dependency.
type PrimitiveType = string | number | boolean | null | undefined | Date;

type FormatXMLElementFn<T, R = string | T | Array<string | T>> = (parts: Array<string | T>) => R;

export const renderOverrideLabel = (
	formatMessage: IntlShape['formatMessage'],
	descriptor?: MessageDescriptor,
	values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
) => {
	if (descriptor) {
		return formatMessage(descriptor, values);
	}
};
