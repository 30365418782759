import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	navigate: {
		id: 'roadmap.timeline-table-kit.chart-item-content.date-content.navigate-button.weeks',
		defaultMessage: 'Navigate to work item dates',
		description:
			'Label for the navigate button on the project timeline when the issue bar is off screen.',
	},
});
