import React, { type SyntheticEvent, useEffect } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { SuccessAutoDismissFlag } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import { getIssueUrl } from './utils.tsx';

export type Props = {
	id: string | number;
	keys: string[];
	isFilterApplied: boolean;
	onClearFilter: () => void;
	onViewIssue: (key: string) => void;
	onDismissed?: (arg1: string | number) => void;
};

const IssueHiddenFlag = ({
	id,
	keys,
	isFilterApplied,
	onClearFilter,
	onViewIssue,
	onDismissed,
}: Props) => {
	const { formatMessage } = useIntl();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		fireTrackAnalytics(
			createAnalyticsEvent({ action: 'viewed', actionSubject: 'issueHiddenFlag' }),
			'viewed issueHiddenFlag',
			{
				isStateChangeBasedIssueHiddenFlagsEnabled: fg(
					'jsw-roadmap-state-change-based-issue-hidden-flags',
				),
			},
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const numIssues = keys.length;

	const onClick =
		numIssues === 1 && onViewIssue
			? (e: SyntheticEvent<HTMLElement, MouseEvent>) => {
					const { ctrlKey, metaKey } = e.nativeEvent;
					if (ctrlKey || metaKey) return;

					e.preventDefault();
					onViewIssue(keys[0]);
				}
			: undefined;

	const getDescriptionMessage = () => {
		if (numIssues === 1) {
			return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.flagDescriptionWithLinkIssueTermRefresh
				: messages.flagDescriptionWithLink;
		}

		return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.flagDescriptionIssueTermRefresh
			: messages.flagDescription;
	};
	const descriptionMessage = getDescriptionMessage();
	const description =
		numIssues === 1 ? (
			<FormattedI18nMessage
				message={formatMessage(descriptionMessage, {
					linkStart: '{linkStart}',
					linkEnd: '{linkEnd}',
				})}
				componentsMapping={{
					link: () => (
						<a href={getIssueUrl([keys[0]])} onClick={onClick} target="_blank">
							{keys[0]}
						</a>
					),
				}}
			/>
		) : (
			formatMessage(descriptionMessage, { numIssues })
		);

	const viewIssueAction = {
		content: formatMessage(
			expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.issueLinkIssueTermRefresh
				: messages.issueLink,
			{ numIssues },
		),
		href: getIssueUrl(keys),
		onClick,
		target: '_blank',
	};

	const clearFilterAction = {
		content: formatMessage(messages.clearFilter),
		onClick: onClearFilter,
	};

	const actions = [];

	if (numIssues > 0) actions.push(viewIssueAction);
	if (isFilterApplied) actions.push(clearFilterAction);

	return (
		<SuccessAutoDismissFlag
			id={id}
			onDismissed={onDismissed}
			title={formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.titleIssueTermRefresh
					: messages.title,
				{ numIssues },
			)}
			description={description}
			actions={actions}
		/>
	);
};

export default IssueHiddenFlag;
