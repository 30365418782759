/** @jsx jsx */
import React, { useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import DependencyLinesOverlay from '@atlassian/jira-aais-dependency-lines-overlay/src/common/ui/index.tsx';
import type {
	SelectedDependency,
	OnDependencyClick,
} from '@atlassian/jira-aais-dependency-lines-overlay/src/common/ui/types.tsx';
import { useTimelineState } from '@atlassian/timeline-table/common/context/timeline';
import { DEPENDENCY_LINES_Z_INDEX } from '../../../common/constants/z-index.tsx';
import type { DependencyIds, DependencyClickCoords, DependencyItem } from './types.tsx';
import { getDependenciesWithPositions } from './utils.tsx';

const containerStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: DEPENDENCY_LINES_Z_INDEX,
});

type Props = {
	dependencies: DependencyItem[];
	selectedDependency?: SelectedDependency;
	onDependencyClick?: (ids: DependencyIds, clickCoords: DependencyClickCoords) => void;
};

const RoadmapDependencyLines = ({ dependencies, selectedDependency, onDependencyClick }: Props) => {
	const [{ timelineDuration, timelineWidth }] = useTimelineState();

	// No need to recalculate positions when the selected dependency changes
	const dependenciesWithPositionsAndIsOverlap = useMemo(
		() => getDependenciesWithPositions(dependencies, timelineDuration, timelineWidth),
		[dependencies, timelineDuration, timelineWidth],
	);

	const onHandleDependencyClick: OnDependencyClick = ({ pageX, pageY }, { toId, fromId }) => {
		onDependencyClick && onDependencyClick({ toId, fromId }, { x: pageX, y: pageY });
	};

	return (
		<div css={containerStyles}>
			<DependencyLinesOverlay
				dependencies={dependenciesWithPositionsAndIsOverlap}
				selectedDependency={selectedDependency}
				onDependencyClick={onHandleDependencyClick}
			/>
		</div>
	);
};

RoadmapDependencyLines.defaultProps = {
	selectedDependency: undefined,
};

export { RoadmapDependencyLines };
