/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type KeyboardEventHandler, memo, useCallback, useState } from 'react';

import { css, jsx } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fg } from '@atlaskit/platform-feature-flags';
import { B100 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { CHART_COLUMN_COORDINATE } from '../../../../common/constants';
import { useSideEffectMarshal } from '../../../../common/context/side-effect-marshal';
import { useGridCell } from '../../../../common/context/side-effect-marshal/focus-marshal/use-grid-cell';
import { getRowClassName } from '../../../../common/context/side-effect-marshal/style-marshal/utils';
import { useTimelineState } from '../../../../common/context/timeline';
import type { ItemId } from '../../../../common/types/item';
import { useItemSelect } from '../../../../common/utils/use-item-select';
import type { RenderChartItemContent } from '../../../../renderers';
import { getContainerHeight } from '../../../utils/get-container-height';

export type Props = {
	id: ItemId;
	parentId: ItemId | undefined;
	depth: number;
	itemHeight: number;
	backgroundColor: string;
	renderChartItemContent: RenderChartItemContent;
};

const itemBaseStyles = css({
	display: 'flex',
	alignItems: 'center',
	transition: 'background-color 100ms linear',
	'&:focus': {
		outline: 'none',
	},
	'&:focus-visible': {
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused', B100)}`,
	},
});

const chartItemContainer = css({
	position: 'relative',
	overflow: 'hidden',
});

const ChartItem = ({
	id,
	parentId,
	depth,
	itemHeight,
	backgroundColor,
	renderChartItemContent,
}: Props) => {
	const [isHovered, setIsHovered] = useState(false);
	const { onRowMouseEnter, onRowMouseLeave } = useSideEffectMarshal();
	const [{ timelineWidth }] = useTimelineState();
	const cellRef = useGridCell(id, CHART_COLUMN_COORDINATE);

	let onCellKeyDown: KeyboardEventHandler | undefined;

	if (fg('project_timeline_multi-select_and_checkboxes')) {
		/* eslint-disable react-hooks/rules-of-hooks */
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { handleKeyDown } = useItemSelect(id);

		onCellKeyDown = useCallback<KeyboardEventHandler<HTMLElement>>(
			(event) => {
				// Handle key events on the container only (not bubbled ones)

				if (event.target === event.currentTarget) {
					const analyticsEvent = createAnalyticsEvent({
						action: 'keydown',
						actionSubject: 'chartItem',
						actionSubjectId: 'issueChartItem',
					});

					handleKeyDown(event, analyticsEvent);
				}
			},
			[createAnalyticsEvent, handleKeyDown],
		);
		/* eslint-enable react-hooks/rules-of-hooks */
	}

	const onMouseEnter = useCallback(() => {
		onRowMouseEnter(id);
		setIsHovered(true);
	}, [id, onRowMouseEnter]);

	const onMouseLeave = useCallback(() => {
		onRowMouseLeave(id);
		setIsHovered(false);
	}, [id, onRowMouseLeave]);

	const rendererProps = { id };
	const rendererState = { isVirtual: false, isHovered };

	const height = getContainerHeight({ itemHeight, depth });

	return (
		<div
			ref={cellRef}
			role="gridcell"
			tabIndex={-1}
			data-testid={`roadmap.timeline-table.components.chart-item.container-${id}`}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={getRowClassName(id, parentId).className}
			css={[itemBaseStyles, chartItemContainer]}
			style={{
				width: `${timelineWidth}px`,
				height: `${height}`,
				backgroundColor,
			}}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onKeyDown={onCellKeyDown}
			onFocus={onMouseEnter}
			onBlur={onMouseLeave}
		>
			{renderChartItemContent(rendererProps, rendererState)}
		</div>
	);
};

ChartItem.displayName = 'ChartItem';
ChartItem.whyDidYouRender = true;

export default memo<Props>(ChartItem);
