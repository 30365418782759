import type { BoundingOptions } from '../common/types';

import type { Offsets } from './types';

export const MISSING_REF_ERROR_MESSAGE =
	'Viewport element was null. Only make this request when it is certain to be available (e.g. not in componentDidMount)';

export const DEFAULT_BOUNDING_OPTIONS: BoundingOptions = {
	withTimelineOffset: true,
};

export const DEFAULT_OFFSETS: Offsets = { scrollbar: 0, timelineTop: 0, timelineLeft: 0 };
