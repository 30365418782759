import { fg } from '@atlaskit/platform-feature-flags';

const GRID_SIZE = 8;
const DEFAULT_LIST_CONTENT_PADDING = GRID_SIZE * 3;
const NON_BASE_LEVEL_LIST_CONTENT_PADDING = 0;
const DEFAULT_LIST_CREATE_OFFSET = -12;
const CHECKBOX_SPACE = 36;
const CHECKBOX_SPACE_OLD = 32;

export const getListContentPaddingLeft = ({
	isCreate = false,
	hasCheckbox = false,
	depth,
	isParent,
	offset,
}: {
	isCreate?: boolean;
	hasCheckbox?: boolean;
	depth: number;
	isParent: boolean;
	offset?: number;
}) => {
	let result = depth * DEFAULT_LIST_CONTENT_PADDING;
	const checkboxSpace = fg('platform-component-visual-refresh')
		? CHECKBOX_SPACE
		: CHECKBOX_SPACE_OLD;

	if (hasCheckbox) {
		result += checkboxSpace;
	}

	if (fg('platform-component-visual-refresh')) {
		result += offset || 0;
	}

	if (isParent) {
		result += NON_BASE_LEVEL_LIST_CONTENT_PADDING;
	} else {
		result += DEFAULT_LIST_CONTENT_PADDING;
	}

	if (isCreate) {
		result += DEFAULT_LIST_CREATE_OFFSET;
	}

	return result;
};
