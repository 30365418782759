import moment from 'moment';

import type { TimelineDuration } from '../../../common/types/timeline';

export const getTodayPosition = (
	today: number,
	timelineDuration: TimelineDuration,
	timelineWidth: number,
) => {
	// We want to present "today" as the middle of the day.
	const middleOfToday = moment.utc(today).add(12, 'hour').valueOf();

	const relativePosition =
		(middleOfToday - timelineDuration.startMilliseconds) / timelineDuration.totalMilliseconds;

	/* Some number magic.
	 * -1 to offset for the width of the today marker.
	 * -1 to offset for the visual weight of the column right border
	 */
	return relativePosition * timelineWidth - 2;
};
