import { fg } from '@atlaskit/platform-feature-flags';
import { token } from '@atlaskit/tokens';

export const getContainerHeight = ({
	itemHeight,
	depth,
}: {
	itemHeight: number;
	depth: number;
}) => {
	const height =
		// eslint-disable-next-line no-nested-ternary
		depth > 0
			? fg('platform-component-visual-refresh')
				? token('space.500', '40px')
				: token('space.400', '32px')
			: `${itemHeight}px`;

	return height;
};
