import { useLayoutEffect } from 'react';

import type { ResizeObserverCallback } from './types';
import { observeElement, runCallbacks, unobserveElement } from './utils';

let resizeObserver: ResizeObserver;

/*
 * Typical resize observer hooks create a new observer for every use. In contrast, the aim here is to provide
 * a single observer for the entire table. This reduces the number of observations, and performs better at scale.
 * This also helps optimise observing multiple *different* elements that refer to the *same* callback. For this case
 * we can perform a single callback with all the relevant entries, rather than multiple callbacks back-to-back.
 */
export const useResizeObserver = (
	element: HTMLElement | null,
	// !! Important - The callback parameter must be referentially stable to avoid an infinite render loop !!
	callback: ResizeObserverCallback,
) => {
	useLayoutEffect(() => {
		if (typeof ResizeObserver !== 'undefined') {
			if (!resizeObserver) {
				resizeObserver = new ResizeObserver(runCallbacks);
			}
			element && observeElement(resizeObserver, element, callback);
		}

		return () => {
			if (element && resizeObserver) {
				unobserveElement(resizeObserver, element, callback);
			}
		};
	}, [element, callback]);
};

export type { ResizeObserverCallback };
