import memoizeOne from 'memoize-one';
import moment from 'moment';

import { fg } from '@atlaskit/platform-feature-flags';

import type { ColumnDuration } from '../../../../../common/types/timeline';
import { getTodayColumnIndex } from '../../../../../common/utils/columns';

import type { WeekNumber } from './types';

export const getColumnMonthNumbers = memoizeOne((columnDurations: ColumnDuration[]) =>
	columnDurations.map<number>((columnDuration) =>
		moment.utc(columnDuration.startMilliseconds).month(),
	),
);

export const getColumnQuarterNumbers = memoizeOne((columnDurations: ColumnDuration[]) =>
	columnDurations.map<number>(
		(columnDuration) => moment.utc(columnDuration.startMilliseconds).quarter() - 1,
	),
);

/* For each column on the timeline, get the human readable year it falls in, e.g. '20
 * All columns that fall in the current year are excluded to reduce UI clutter and improve readability.
 */
export const getColumnYearCaptions = memoizeOne(
	(today: number, columnDurations: ColumnDuration[], isWeekMode = false) => {
		const startOfCurrentYear = moment.utc(today).startOf('year').valueOf();
		const endOfCurrentYear = moment.utc(today).endOf('year').valueOf();

		let cachedYear: string;
		let nextYear: number;

		if (fg('jsw_roadmaps_timeline-fix-year-caption-weeks-mode')) {
			/* Optimisation! Only calculate a caption when the year changes and interpolate the remaining values.
			 * This makes a substantial difference for granular timeline modes (e.g. weeks). */
			return columnDurations.map<string | undefined>(({ startMilliseconds, totalMilliseconds }) => {
				let columnTime = startMilliseconds;
				if (isWeekMode) {
					columnTime += totalMilliseconds / 2;
				}

				if (columnTime < startOfCurrentYear || columnTime > endOfCurrentYear) {
					if (!cachedYear || columnTime >= nextYear) {
						const columnMoment = moment.utc(columnTime);
						cachedYear = `'${columnMoment.year().toString().slice(2)}`;
						nextYear = columnMoment.add(1, 'year').startOf('year').valueOf();
					}

					return cachedYear;
				}
				return undefined;
			});
		}

		/* Optimisation! Only calculate a caption when the year changes and interpolate the remaining values.
		 * This makes a substantial difference for granular timeline modes (e.g. weeks). */
		return columnDurations.map<string | undefined>(({ startMilliseconds }) => {
			if (startMilliseconds < startOfCurrentYear || startMilliseconds > endOfCurrentYear) {
				if (!cachedYear || startMilliseconds >= nextYear) {
					const columnMoment = moment.utc(startMilliseconds);
					cachedYear = `'${columnMoment.year().toString().slice(2)}`;
					nextYear = columnMoment.add(1, 'year').startOf('year').valueOf();
				}

				return cachedYear;
			}
			return undefined;
		});
	},
);

/* For each column on the timeline, get the numeric representation of the day and month it falls in.
 * This format is used to simplify various view logic *before* being mapped to human readable strings.
 */
export const getColumnWeekNumbers = memoizeOne((columnDurations: ColumnDuration[]) => {
	const daysArray = [...new Array(7)];

	/* Optimisation! By leveraging moment's mutative paradigm we can avoid creating an
	 * expensive moment object for every inner loop - or O(N) vs O(N^2) work. */
	return columnDurations.map<WeekNumber[]>(({ startMilliseconds }) => {
		const columnMoment = moment.utc(startMilliseconds);

		const weekNumbers: WeekNumber[] = daysArray.map(() => {
			const data = { day: columnMoment.date(), month: columnMoment.month() };
			columnMoment.add(1, 'day');
			return data;
		});

		return weekNumbers;
	});
});

// Get the [column, day] co-ordinates that represent today
export const getTodayCoordinates = memoizeOne(
	(today: number, columnDurations: ColumnDuration[]): [number, number] => {
		let todayDayIndex = 0;
		const todayNumber = moment.utc(today).date();
		const todayColumnIndex = getTodayColumnIndex(today, columnDurations);
		const weekNumbers = getColumnWeekNumbers(columnDurations);
		const weekOfToday = weekNumbers[todayColumnIndex];

		weekOfToday.forEach((weekNumber: WeekNumber, index: number) => {
			if (weekNumber.day === todayNumber) {
				todayDayIndex = index;
			}
		});

		return [todayColumnIndex, todayDayIndex];
	},
);
