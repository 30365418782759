import { type RefObject, useEffect } from 'react';

export const useOnClickOutside = ({
	menuRef,
	cellRef,
	triggerRef,
	closeMenu,
	isMenuOpen,
	onStopHoverOrFocus,
}: {
	menuRef: RefObject<HTMLDivElement | HTMLButtonElement>;
	triggerRef: RefObject<HTMLButtonElement>;
	closeMenu: () => void;
	isMenuOpen: boolean;
	cellRef?: RefObject<HTMLElement | null>;
	onStopHoverOrFocus?: () => void;
}) => {
	useEffect(() => {
		const handleOnClickOutsideMenu = ({ target }: MouseEvent) => {
			if (target instanceof Node) {
				// If the click is outside the item cell clear the cell focus and close the menu
				if (isMenuOpen && cellRef && !cellRef.current?.contains(target)) {
					onStopHoverOrFocus?.();
				}
				// If the click is outside the menu and the trigger(meatball button), close the menu
				else if (
					isMenuOpen &&
					!triggerRef.current?.contains(target) &&
					!menuRef.current?.contains(target)
				) {
					closeMenu();
				}
			}
		};
		if (typeof document !== 'undefined') {
			// eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
			document.addEventListener('mousedown', handleOnClickOutsideMenu);
		}

		return () => {
			if (typeof document !== 'undefined') {
				// eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
				document.removeEventListener('mousedown', handleOnClickOutsideMenu);
			}
		};
	}, [cellRef, closeMenu, isMenuOpen, menuRef, onStopHoverOrFocus, triggerRef]);
};
