import type { Action } from 'react-sweet-state';

import type { State } from '../../common/types';

type Params = {
	left: number;
	clientWidth: number;
	indicatorLineOffset: number;
};

export type OnResizeAction = (params: Partial<Params>) => Action<State>;

export const onResize: OnResizeAction =
	(params: Partial<Params>) =>
	({ setState }) => {
		setState({ ...params });
	};
