import type { Action } from 'react-sweet-state';

import type { State } from '../../common/types';

export type SetListWidthAction = (listWidth: number) => Action<State>;

export const setListWidth: SetListWidthAction =
	(listWidth: number) =>
	({ setState }) => {
		setState({ listWidth });
	};
