import { fg } from '@atlaskit/platform-feature-flags';

/* Default depth for an item in the table hierarchy.
 * despite the level, the depth is increasing when we go deeper in the hierarchy.
 * depth is starting from 0.
 */
export const DEFAULT_DEPTH = 0;
/* Default level for an item in the table hierarchy.
 * When hierarchy information is absent, a flat table should display items at this level.
 */
export const DEFAULT_LEVEL = 1;

// Amount that window resize event handlers should be debounced by
export const RESIZE_DEBOUNCE_MS = 250;

export const BASE_LEVEL = 0 as const;
export const PARENT_LEVEL = 1 as const;

// ============= //
// === SIZES === //
// ============= //

// Convert to number when cleaning up
// The default row (calendar header)
export const BASE_HEADER_HEIGHT = () =>
	// eslint-disable-next-line @atlaskit/platform/no-preconditioning, @atlaskit/platform/ensure-feature-flag-prefix
	fg('platform-component-visual-refresh') && fg('visual-refresh_drop_4') ? 40 : 48;

export const HEADER_CONTENT_HEIGHT = 16;
export const DEFAULT_SUBHEADER_HEIGHT = 36;

export const DEFAULT_ITEM_HEIGHT = 40;
// Convert to number when cleaning up
export const BASE_LEVEL_ITEM_HEIGHT = () => (fg('platform-component-visual-refresh') ? 40 : 32);

// Convert to number when cleaning up project_timeline_multi-select_and_checkboxes
export const MINIMUM_LIST_WIDTH = () =>
	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	fg('project_timeline_multi-select_and_checkboxes') ? 384 : 320;
export const DEFAULT_LIST_WIDTH = () =>
	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	fg('project_timeline_multi-select_and_checkboxes') ? 424 : 360;

// ===================== //
// === HEADER ROW ID === //
// ===================== //

export const DEFAULT_HEADER_ROW_ID = 'DEFAULT_HEADER_ROW' as const;

// ==================== //
// === DRAG N' DROP === //
// ==================== //

export const UP = 'UP' as const;
export const DOWN = 'DOWN' as const;
export const ROW_DRAG_AND_DROP_ID = 'row-dnd-type-id';
export const LIST_DRAG_AND_DROP = 'listDnd' as const;
export const CHILD_LIST_DRAG_AND_DROP = 'childListDnd' as const;
export const LIST_RESIZE_DRAG_AND_DROP = 'listResizeDnd' as const;

// Delay the expanding of a parent issue when dragged-over
export const DELAY_EXPAND_TIME = 500;

// ============== //
// === CREATE === //
// ============== //

export const BEFORE = 'BEFORE' as const;
export const INSIDE = 'INSIDE' as const;
export const LAST = 'LAST' as const;

// ================ //
// === TIMELINE === //
// ================ //

export const TIMELINE_ID = 'sr-timeline' as const;
export const WEEKS = 'WEEKS' as const;
export const MONTHS = 'MONTHS' as const;
export const QUARTERS = 'QUARTERS' as const;
export const INLINE_CREATE_ICON_WIDTH = 20;
export const HALF_INLINE_CREATE_ICON_WIDTH = INLINE_CREATE_ICON_WIDTH / 2;

// =============== //
// === Z-INDEX === //
// =============== //

const CHART = 1;
const TODAY_MARKER = 6;
const HEADER = 7;
const LIST_COVER = 8;
const LIST = 9;
const LIST_CREATE_ITEM = 10;
const INLINE_INDICATOR = 11;
const SCROLLBAR = 11;
const ROW_HEADER = 12;
const BORDER = 12;
const LIST_RESIZER = 13;
const DRAGGABLE = 13;
const PORTAL = 13;
const HIGHEST = 14;

export const zIndex = {
	CHART,
	BORDER,
	TODAY_MARKER,
	LIST_COVER,
	HEADER,
	LIST,
	LIST_CREATE_ITEM,
	INLINE_INDICATOR,
	SCROLLBAR,
	ROW_HEADER,
	LIST_RESIZER,
	DRAGGABLE,
	PORTAL,
	HIGHEST,
};

// =============================== //
// === HIERARCHY ENRICHED ITEM === //
// =============================== //

export const DEFAULT_CREATE_ID = 'create-default';
export const ITEM_TYPE = 'ITEM_TYPE' as const;
export const CREATE_ITEM_TYPE = 'CREATE_ITEM_TYPE' as const;
export const SCROLLED = 'scrolled' as const;

// ============ //
// === A11Y === //
// ============ //

// So long as we have a two column layout this can remain static
export const LIST_COLUMN_COORDINATE = 0;
export const CHART_COLUMN_COORDINATE = 1;

// ================================ //
// === LIST ITEM MENU OPTION IDS=== //
// ================================ //

const ITEMS_SECTION = 'itemsSection' as const;
const CREATE_ITEM = 'createItem' as const;
const MOVE_ITEM = 'moveItem' as const;
const CREATE_CHILD_ITEM = 'createChildItem' as const;
const CREATE_ITEM_ABOVE = 'createItemAbove' as const;
const CREATE_ITEM_BELOW = 'createItemBelow' as const;
const MOVE_ITEM_UP = 'moveItemUp' as const;
const MOVE_ITEM_DOWN = 'moveItemDown' as const;
const MOVE_ITEM_TO_TOP = 'moveItemToTop' as const;
const MOVE_ITEM_TO_BOTTOM = 'moveItemToBottom' as const;
const SELECTED_ITEM_COUNTER = 'selectedItemCounter' as const;

export const listItemMenu = {
	SELECTED_ITEM_COUNTER,
	ITEMS_SECTION,
	CREATE_ITEM,
	MOVE_ITEM,
	CREATE_CHILD_ITEM,
	CREATE_ITEM_ABOVE,
	CREATE_ITEM_BELOW,
	MOVE_ITEM_UP,
	MOVE_ITEM_DOWN,
	MOVE_ITEM_TO_TOP,
	MOVE_ITEM_TO_BOTTOM,
};

export const DEFAULT_MENU_MAX_HEIGHT = 480;
