import React, { type ReactNode, useCallback } from 'react';
import noop from 'lodash/noop';
import { WarningFlag } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages.tsx';

export type Props = {
	id: string | number;
	onDismissed: (id: string | number) => void;
	setShowDependencies: () => void;
	dependee?: IssueKey;
	dependency?: IssueKey;
	isDependenciesVisible: boolean;
	isDependencyDuplicated: boolean;
};

export const FlagType = {
	DUPLICATED: 'DUPLICATED',
	DUPLICATED_HIDDEN: 'DUPLICATED_HIDDEN',
	CYCLE: 'CYCLE',
	CYCLE_HIDDEN: 'CYCLE_HIDDEN',
} as const;

const DependencyInvalidFlag = ({
	id,
	onDismissed,
	setShowDependencies,
	dependee,
	dependency,
	isDependenciesVisible,
	isDependencyDuplicated,
}: Props) => {
	const onDismissedCallback = useCallback(() => {
		onDismissed(id);
	}, [id, onDismissed]);

	const onShowDependencies = useCallback(() => {
		setShowDependencies();
		onDismissedCallback();
	}, [setShowDependencies, onDismissedCallback]);

	let flagType: keyof typeof FlagType;

	if (isDependenciesVisible) {
		flagType = isDependencyDuplicated ? FlagType.DUPLICATED : FlagType.CYCLE;
	} else {
		flagType = isDependencyDuplicated ? FlagType.DUPLICATED_HIDDEN : FlagType.CYCLE_HIDDEN;
	}

	const getDescription = () => {
		switch (flagType) {
			case FlagType.DUPLICATED:
				return messages.duplicatedDescription;
			case FlagType.DUPLICATED_HIDDEN:
				return messages.duplicatedDescriptionHidden;
			case FlagType.CYCLE:
				return messages.cycleDescription;
			case FlagType.CYCLE_HIDDEN:
				return messages.cycleDescriptionHidden;
			default:
				return messages.duplicatedDescription;
		}
	};

	const { formatMessage } = useIntl();

	if (!dependee || !dependency) {
		return null;
	}

	return (
		<WarningFlag
			delayAnnouncement={300}
			id={id}
			onDismissed={onDismissedCallback}
			title={formatMessage(
				flagType === FlagType.DUPLICATED || flagType === FlagType.DUPLICATED_HIDDEN
					? messages.duplicatedTitle
					: messages.cycleTitle,
			)}
			description={formatMessage(getDescription(), {
				b: (chunks: ReactNode[]) => <b>{chunks}</b>,
				dependency,
				dependee,
			})}
			{...(flagType === FlagType.CYCLE_HIDDEN || flagType === FlagType.DUPLICATED_HIDDEN
				? {
						actions: [
							{
								content: formatMessage(messages.showDependencies),
								onClick: onShowDependencies,
							},
							{
								content: formatMessage(messages.confirm),
								onClick: onDismissedCallback,
							},
						],
					}
				: {})}
		/>
	);
};

DependencyInvalidFlag.defaultProps = { onDismissed: noop };

export default DependencyInvalidFlag;
