import React, {
	forwardRef,
	type KeyboardEvent,
	type MouseEvent,
	type Ref,
	useCallback,
	useEffect,
} from 'react';

import { useIntl } from 'react-intl-next';
import { mergeRefs } from 'use-callback-ref';

import { IconButton } from '@atlaskit/button/new';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import { MenuGroup } from '@atlaskit/menu';
import { fg } from '@atlaskit/platform-feature-flags';
import Popup, { type ContentProps, type TriggerProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';

import { DEFAULT_MENU_MAX_HEIGHT } from '../../../../../../common/constants';
import {
	useIsItemMultiSelected,
	useItemsSelectionCount,
} from '../../../../../../common/context/items';
import { useIsScrollingY } from '../../../../../../common/context/scroll-meta/main';
import { useFocusMarshal } from '../../../../../../common/context/side-effect-marshal/focus-marshal';
import { useCreateTrigger } from '../../../../../../common/context/side-effect-marshal/focus-marshal/use-create-trigger';
import type { MeatballPopupProps } from '../types';

import messages from './messages';
import MultiSelectOptions from './multi-select-options';
import SingleSelectOptions from './single-select-options';

const MeatballPopup = forwardRef(
	(
		{
			id,
			isOpen,
			issuesSectionOptions,
			customOtherSections,
			parentId,
			level,
			depth,
			onDrop,
			menuRef,
			isCreateChildEnabled,
			isCreateSiblingEnabled,
			onInlineCreateClicked,
			renderListItemMenuOption,
			onMenuToggle,
			setCloseMenu,
			setOpenModal,
		}: MeatballPopupProps,
		ref: Ref<HTMLButtonElement>,
	) => {
		const { createTriggerRef, onCreateTriggerClick } = useCreateTrigger(id, 'MEATBALLS');
		const [isMultiSelected] = useIsItemMultiSelected(id);
		const [isScrollingY] = useIsScrollingY();
		const [itemsSelectionCount] = useItemsSelectionCount();
		const { formatMessage } = useIntl();
		let itemsSelectionAriaLabel = '';
		if (isMultiSelected) {
			itemsSelectionAriaLabel = formatMessage(messages.itemsSelectionCount, {
				count: itemsSelectionCount,
			});
		}

		const { shouldPreventNavigation } = useFocusMarshal();

		// Manages closing the meatballs menu when the timeline is scrolled.
		useEffect(() => {
			if (isScrollingY && isOpen) {
				setCloseMenu();
			}
		}, [isOpen, isScrollingY, setCloseMenu]);

		const renderMenu = useCallback(
			({ setInitialFocusRef }: ContentProps) => (
				<Box ref={menuRef} xcss={menuWrapStyles}>
					<MenuGroup
						testId={`roadmap.timeline-table.main.content.list.list-item.meatballs-button.meatball-popup.menu-${id}`}
						maxHeight={DEFAULT_MENU_MAX_HEIGHT}
					>
						{isMultiSelected && fg('jsw_roadmap_timeline_meatball_selected_item_option') ? (
							<MultiSelectOptions
								id={id}
								renderListItemMenuOption={renderListItemMenuOption}
								setOpenModal={setOpenModal}
								onCreateTriggerClick={onCreateTriggerClick}
								setInitialFocusRef={setInitialFocusRef}
								setCloseMenu={setCloseMenu}
							/>
						) : (
							<SingleSelectOptions
								issuesSectionOptions={issuesSectionOptions}
								customOtherSections={customOtherSections}
								id={id}
								parentId={parentId}
								level={level}
								depth={depth}
								isCreateChildEnabled={isCreateChildEnabled}
								isCreateSiblingEnabled={isCreateSiblingEnabled}
								onInlineCreateClicked={onInlineCreateClicked}
								setInitialFocusRef={setInitialFocusRef}
								onDrop={onDrop}
								renderListItemMenuOption={renderListItemMenuOption}
								setCloseMenu={setCloseMenu}
								setOpenModal={setOpenModal}
								onCreateTriggerClick={onCreateTriggerClick}
							/>
						)}
					</MenuGroup>
				</Box>
			),
			[
				customOtherSections,
				depth,
				id,
				isCreateChildEnabled,
				isCreateSiblingEnabled,
				isMultiSelected,
				issuesSectionOptions,
				level,
				menuRef,
				onCreateTriggerClick,
				onDrop,
				onInlineCreateClicked,
				parentId,
				renderListItemMenuOption,
				setCloseMenu,
				setOpenModal,
			],
		);

		const onClick = useCallback(
			(e: React.MouseEvent<Element> | React.KeyboardEvent<Element>) => {
				shouldPreventNavigation(true);
				onMenuToggle(e);
			},
			[shouldPreventNavigation, onMenuToggle],
		);

		const trigger = useCallback(
			({ ref: triggerRef, ...triggerProps }: TriggerProps) => (
				<Box xcss={buttonWrapStyles}>
					<IconButton
						{...triggerProps}
						ref={mergeRefs([ref, createTriggerRef, triggerRef])}
						onClick={fg('timeline_grid_navigation_m2') ? onClick : onMenuToggle}
						spacing="compact"
						testId={`roadmap.timeline-table.main.content.list.list-item.more-options-button.meatballs-menu-${id}`}
						label={
							itemsSelectionAriaLabel && fg('jsw_roadmap_timeline_meatball_selected_item_option')
								? `${formatMessage(messages.meatballsButtonLabel)} ${itemsSelectionAriaLabel}`
								: formatMessage(messages.meatballsButtonLabel)
						}
						icon={MoreIcon}
						interactionName="timeline-meatballs-trigger-clicked"
						appearance={fg('platform-component-visual-refresh') ? 'subtle' : 'default'}
						{...(fg('chronos_a11y_fixes_jtran2')
							? {
									'aria-labelledby': `timeline-meatballs-trigger-${id} timeline-summary-key-${id} timeline-summary-title-${id}`,
									id: `timeline-meatballs-trigger-${id}`,
								}
							: {})}
					/>
				</Box>
			),
			[createTriggerRef, formatMessage, id, itemsSelectionAriaLabel, onClick, onMenuToggle, ref],
		);

		const onPopupClose = useCallback(
			(event: MouseEvent | KeyboardEvent) => setCloseMenu(event),
			[setCloseMenu],
		);

		return (
			<Popup
				role="dialog"
				shouldFlip={false}
				shouldRenderToParent
				shouldReturnFocus={false}
				shouldUseCaptureOnOutsideClick
				onClose={onPopupClose}
				placement="right-start"
				isOpen={isOpen}
				content={renderMenu}
				trigger={trigger}
			/>
		);
	},
);

const buttonWrapStyles = xcss({
	marginLeft: 'space.050',
});

const menuWrapStyles = xcss({
	cursor: 'default',
});

export default MeatballPopup;
