/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { memo } from 'react';

import { css, jsx, keyframes } from '@compiled/react';
import { useIntl } from 'react-intl-next';

import EditorAddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import { B300, N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import {
	HALF_INLINE_CREATE_ICON_WIDTH,
	INLINE_CREATE_ICON_WIDTH,
	zIndex,
} from '../../../../common/constants';
import {
	getInlineCreateButtonClassName,
	getInlineCreateIconClassName,
} from '../../../../common/context/side-effect-marshal/style-marshal/utils';

import messages from './messages';

const appear = keyframes({
	from: {
		opacity: 0,
	},
	to: {
		opacity: 1,
	},
});

const opacityTransitionStyles = css({
	animationName: appear,
	opacity: 0,
	animationDuration: '0.2s',
	animationDelay: '0.3s',
	animationFillMode: 'forwards',
});

const iconWrapperStyles = css({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${INLINE_CREATE_ICON_WIDTH}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${INLINE_CREATE_ICON_WIDTH}px`,
	top: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values
	left: `-${HALF_INLINE_CREATE_ICON_WIDTH}px`,
	backgroundColor: token('color.icon.selected', B300),
	color: token('color.text.inverse', N0),
	borderRadius: token('border.radius', '3px'),
});

const iconContainerStyles = css({
	height: '24px',
	width: '24px',
	position: 'relative',
	marginLeft: token('space.negative.025', '-2px'),
	marginTop: token('space.negative.025', '-2px'),
});

const lineStyles = css({
	position: 'relative',
	top: '9px',
	height: '2px',
	backgroundColor: token('color.border.selected', B300),
});

const containerStyles = css({
	display: 'none',
	position: 'absolute',
	top: 0,
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.INLINE_INDICATOR,
});

const outerButtonStyles = css({
	position: 'relative',
	top: '-5px',
	left: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${INLINE_CREATE_ICON_WIDTH}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `calc(100% + ${HALF_INLINE_CREATE_ICON_WIDTH}px)`,
	cursor: 'pointer',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/design-system/no-nested-styles -- Ignored via go/DSP-18766
		'[data-component-selector="icon-wrapper"]': {
			opacity: 1,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/design-system/no-nested-styles -- Ignored via go/DSP-18766
		'[data-component-selector="line"]': {
			opacity: 1,
		},
	},
});

type Props = {
	left: number;
	width: number;
};

const InlineCreateIndicator = ({ left, width }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={getInlineCreateButtonClassName().className}
			css={[containerStyles, opacityTransitionStyles]}
			style={{
				left: `${left}px`,
			}}
		>
			<div css={outerButtonStyles}>
				<div
					data-component-selector="line"
					css={[lineStyles]}
					style={{
						width: `${width}px`,
					}}
				/>
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
					className={getInlineCreateIconClassName().className}
					data-component-selector="icon-wrapper"
					css={iconWrapperStyles}
				>
					<div css={iconContainerStyles}>
						<EditorAddIcon spacing="spacious" label={formatMessage(messages.label)} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo<Props>(InlineCreateIndicator);
