/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { memo, type MouseEvent, useCallback } from 'react';

import { css, jsx } from '@compiled/react';

import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { fg } from '@atlaskit/platform-feature-flags';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { B100 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import type { OnItemExpandChanged } from '../../../../../common/types/callbacks';
import type { ItemId } from '../../../../../common/types/item';

const buttonStyles = css({
	height: '24px',
	width: '24px',
	padding: 0,
	margin: 0,
	border: 'none',
	backgroundColor: 'transparent',
	color: token('color.icon.subtle'),
	cursor: 'pointer',
	flex: '0 0 auto',
	outline: 'none',
	'&:focus': {
		outline: 'none',
	},
	'&:focus-visible': {
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused', B100)}`,
	},
});

const buttonStylesOld = css({
	height: '24px',
	width: '24px',
	padding: 0,
	margin: 0,
	border: 'none',
	backgroundColor: 'transparent',
	cursor: 'pointer',
	flex: '0 0 auto',
	outline: 'none',
	'&:focus': {
		outline: 'none',
	},
	'&:focus-visible': {
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused', B100)}`,
	},
});

type Props = {
	isExpanded: boolean;
	// Remove with timeline_grid_navigation_m2
	isRichContentEnabled: boolean;
	id: ItemId;
	label: string;
	ariaControls?: string;
	onExpandChanged: OnItemExpandChanged;
};

const ExpandButton = ({ id, label, isExpanded, ariaControls, onExpandChanged }: Props) => {
	const onClick = useCallback(
		(event: MouseEvent<HTMLButtonElement>) => {
			// Do not consider clicking this as also clicking on the list item itself
			event.stopPropagation();
			traceUFOPress('timeline-expand-changed', event.timeStamp);
			onExpandChanged(id, isExpanded);
		},
		[id, isExpanded, onExpandChanged],
	);

	const Chevron = isExpanded ? ChevronDownIcon : ChevronRightIcon;
	const chevronElement = (
		<Chevron
			testId={`roadmap.timeline-table.components.list-item.expand-button.container-${id}`}
			label=""
		/>
	);

	return (
		// eslint-disable-next-line @atlaskit/design-system/no-html-button
		<button
			css={[
				// eslint-disable-next-line @atlaskit/platform/no-preconditioning, @atlaskit/platform/ensure-feature-flag-prefix
				fg('platform-component-visual-refresh') && fg('visual-refresh_drop_4')
					? buttonStyles
					: buttonStylesOld,
			]}
			onClick={onClick}
			aria-expanded={isExpanded}
			aria-label={label}
			{...(isExpanded &&
				ariaControls && {
					'aria-controls': ariaControls,
				})}
		>
			{chevronElement}
		</button>
	);
};

export default memo<Props>(ExpandButton);
