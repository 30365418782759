import React, { type MouseEvent, useCallback } from 'react';

import { useIntl } from 'react-intl-next';

import { Checkbox } from '@atlaskit/checkbox';

import messages from './messages';

interface Props {
	id: string;
	onToggle: (e: MouseEvent<HTMLInputElement>) => void;
	isChecked: boolean;
	isDisabled: boolean;
}

const ListItemSelectCheckbox: React.FC<Props> = ({ id, onToggle, isChecked, isDisabled }) => {
	const { formatMessage } = useIntl();
	const handleClick = useCallback(
		(e: MouseEvent<HTMLInputElement>) => {
			e.stopPropagation();
			onToggle(e);
		},
		[onToggle],
	);

	return (
		<Checkbox
			value={`${id} selected`}
			aria-label={formatMessage(messages.label)}
			isChecked={isChecked}
			isDisabled={isDisabled}
			onClick={handleClick}
		/>
	);
};

export default ListItemSelectCheckbox;
