/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useTimelineState } from '@atlassian/timeline-table/common/context/timeline';
import { MARKER_COLORS } from '../../../common/constants/key-date.tsx';
import { HIGHLIGHT_LINES_Z_INDEX } from '../../../common/constants/z-index.tsx';
import type { KeyDate } from '../../../common/types/key-date.tsx';
import { getKeyDatesGroups } from '../../../common/utils/key-date/grouping.tsx';
import { getHighlightLines } from '../../../common/utils/key-date/highlight-lines.tsx';

type Props = {
	highlightedKeyDates: string[];
	keyDates: ReadonlyArray<KeyDate>;
};

const RoadmapHighlightLines = ({ highlightedKeyDates, keyDates }: Props) => {
	const [{ timelineDuration, timelineWidth, timelineMode }] = useTimelineState();

	if (highlightedKeyDates.length === 0) {
		return null;
	}

	const keyDateGroups = getKeyDatesGroups({
		keyDates,
		timelineMode,
		timelineDuration,
		timelineWidth,
	});

	const highlightLines = getHighlightLines({ keyDateGroups, highlightedKeyDates });

	return (
		<>
			{highlightLines.map(({ left, id, status }) => (
				<div
					key={id}
					css={highlightLineStyles}
					style={{
						left: `${left}px`,
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						backgroundColor: MARKER_COLORS[status],
					}}
					data-testid={`roadmap.timeline-table-kit.ui.chart-timeline-overlay.highlight-lines.highlight-line-${id}`}
				/>
			))}
		</>
	);
};

export { RoadmapHighlightLines };

const highlightLineStyles = css({
	height: '100%',
	width: token('space.025', '2px'),
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: HIGHLIGHT_LINES_Z_INDEX,
});
